import React from 'react';
import { Container, Section, Image, Column, Title, Padding, Header, Wrapper, WrapperWrapper } from './styled';
import { Inline, Paragraph } from '../../styles';
import Config from '../../config';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        text: state.language.about
    }
}

class Certificate extends React.Component {
    render() {
        return (

            <Container>
                {
                    this.props.text ?
                        <>
                            <Column>
                                <Inline>
                                    <Section first>
                                        <Image src={Config.images.home.image_3} />
                                    </Section>
                                    <Section graySpace={true}>
                                        <Padding>
                                            <Header>
                                                <WrapperWrapper><Title>{this.props.text.certificate_title}<br /></Title></WrapperWrapper>

                                            </Header>
                                            <Paragraph small={true}>{this.props.text.certificate_text}</Paragraph>
                                        </Padding>
                                    </Section>
                                </Inline>
                            </Column>
                        </>
                        :
                        <>
                        </>
                }
            </Container>

        );
    }
}

export default connect(mapStateToProps, null)(Certificate);