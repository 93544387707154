
import certificate_1_pdf from '../assets/pdf/home/tuv-nord.pdf';
import certificate_2_pdf from '../assets/pdf/home/schmitz-cargobull.PDF';
import certificate_3_pdf from '../assets/pdf/home/licenca-megga.pdf';
import certificate_4_pdf from '../assets/pdf/home/licenca-dragan.pdf';
import certificate_5_pdf from '../assets/pdf/home/megga-prikolice.pdf';

const pdf = {
    home: {
        certificate_1_pdf,
        certificate_2_pdf,
        certificate_3_pdf,
        certificate_4_pdf,
        certificate_5_pdf,
    }
}

export default pdf;