const eng = {
    lang: 'en',
    version: 2,
    navigation: {
        home: 'HOME',
        about: 'ABOUT US',
        certificate: 'GALLERY',
        history: 'HISTORY',
        contact: 'CONTACT',
        language: 'ENGLISH',
        languageShort: 'EN',
        languages: {
            english: 'ENGLISH',
            german: 'GERMAN',
            serbian: 'SERBIAN'
        }
    },
    about: {
        orangeBox: 'SHIPPING\n TAILOR MADE\n FOR YOUR NEEDS',
        about: 'ABOUT US',
        about_text_1: 'We are the company MEGGA doo from Požega in Serbia. Our core business is international transport and freight forwarding. Thanks to the tradition and inspiration of the founders, productivity and motivation of all employees, we are a superbly organized transport company.',
        about_text_2: "With extensive experience in transportation and freight forwarding, we were able to understand all the key aspects important for our customers. Our focus is always on what the partner requires. The common goal of our team is our partners' profit. We follow every step from booking to realization. This is what sets us apart from others in the world of road freight transport.",
        title_1: "MEGGA FLEET",
        text_1: "The Megga fleet consists of trucks from the most renowned European truck manufacturers with the latest technological developments and the Euro6 standard. The trailers are unique due to their 3m inside height (mega trailers). This is what makes us renowned. The average age of our vehicles is 3 years. All our vehicles are equipped with the latest GPS systems and are monitored seven days a week, 24 hours a day. We are always in the right place at the right time. The key task of our truck fleet is the safety of goods during transport.",
        title_2: "MEGGA DRIVERS",
        text_2: "Our drivers are professional, responsible, reliable and are the most numerous members of our team. They are professionals who are highly motivated and dedicated to their work. The key objective of our drivers is traffic safety.",
        certificate_title: "CERTIFICATES",
        certificate_text: "In order to meet the most important demands for the safety of goods during transport and traffic safety, our company is especially dedicated to standardization. This is why our company, our fleet and our drivers hold multiple internationally recognized certificates. Like all socially responsible companies, we dedicate great care and attention to preserving the environment in which we live and operate.",
        locations_title: "ROUTES",
        locations_text: "Our most frequent routes are from Serbia and the Balkans to Austria, Germany, France, Belgium, the Netherlands, Poland, the Czech Republic and Slovakia. We are always ready to open other transport routes at the request of our partners.",
        partners_title: "PARTNERS ABOUT US",
        partners_content: [
            {
                name: 'Heidi Gmeiner',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Very caring company, employees very sympathic and helpful. We can absolutely recommend company Megga for transports from Austria to Serbia/Kosovo/Macedonia."
            },
            {
                name: 'Mischa Jovanovic',
                title: 'CEO and Founder\nAQUAWELL GROUP AG\nSaint John’s Water',
                text: "As an international business company, it was very important for us to have a reliable and sound partner for cooperation. Megga is the only company in Serbia that has met all our requirements and is dedicated, professional and efficient in the transportation to all necessary destinations in the delivery of goods. Megga is a reliable partner and we have cooperated for several years with utmost satisfaction. During all these years of cooperation with Megga, we have received only the highest quality services, without delays or damage to goods.",
            },
            {
                name: 'Goran Knežević',
                title: 'Easytrip Transport Services',
                text: "What a good partnership !\n\n The collaboration between Easytrip and Megga started few years ago when we meet on fair organized by MDT System Kraljevo. The company was looking for Slovakian toll service and from that day we have built a very good business relationship based on effectiveness and professionalism. Today we cooperate on almost all European countries.\n\n Megga provides an excellent support to his clients and I’m proud to be part of their success. As a reliable supplier and expert in their transport business, I can confidently recommend this company.",
            },
        ]
    },
    history: {
        orangeBox: 'TRANSPORTATION TRADITION',
        history: 'MEGGA COMPANY HISTORY',
        historyText: 'The company MEGGA doo Požega is a family owned company with roots in the 1920s, with a tradition of almost 100 years.',
        historyText2: 'The first business motto was: ',
        moto: '„The world rests with the younger generation.“',
        historyText3: "So the foundation of today's business success back in the twenties of the last century was established by our great-grandfather, Dobrivoje Gligorijević, who started our partnership in the field of freight transportation in the 1930s. He began the business with the food and animal feed transport. He transported hay, grain, fruit and machinery for food production and processing using ox/horse drawn vehicles.",
        text1: "And as early as the 1950s and 1960s, our great-grandfather readily anticipated the industrial revolution in the motor vehicle industry and the appearance of the first heavy goods vehicles, directing his successors and son to learn to  drive and operate the first heavy trucks.",
        text2: 'Then in the 1970s and 1980s, as a truck driver in the major transport giants, our grandfather Radmilo Gajo Gligorijević continued and perfected the  domestic and international transport.',
        text3: 'In the 1970s and 1980s, as a truck driver in the major transport giants, the work of transport in domestic and international transport was continued and perfected by our grandfather, Radmilo Gajo Gligorijević.',
        text4: "In the early 2000s, my father, Dragan Gligorijević, took the initiative in the family business with the founding of MEGGA doo Požega. He has accepted the challenge of tradition, adopted and implemented new and modern know-how and technologies. That is how he developed the company operations to a higher level to present time, when I am intensively preparing for the continuation of this business. I, Stefan Gligorijević am grateful to the tradition.",
    },
    contact: {
        orangeBox: 'WHEN TRANSPORT\n IS REQUIRED,\n CONTACT MEGGA',
        title: 'CONTACT',
        name: 'Name and surname',
        email: 'E mail',
        message: 'Message',
        send: 'SEND',
        info: "Megga doo\nVojvode Mišića 7\nPožega 31210\n\nE-mail: office@megga.pro",
        telephone: "Phone: +381 31 715 690\n     +381 31 715 729",
        success: 'Your message was sent successfully!',
        close: 'Close',
        failed: 'Failed to send a message',
        captcha: 'Incorrect captcha',
        messageError: 'Message is to short',
        nameError: 'The name field cannot be left empty',
        emailError: 'Please include an @ in the email address',
        textError: 'Text should be minimum 50 characters',
    },
    legal: {
        copyright: {
            title: 'COPYRIGHT',
            text: "The website www.megga.pro and all the content on this site are owned by Megga\ndoo © 2020 Megga doo. All copyrights and other rights are reserved by the owner.\n\nProperty rights specifically relate to:\n1. The Website ''www.megga.pro'';\n2. All content and material on meggapozega.com, including: all photographs and\nvideos on the Website, texts, programming code, and anything else that may be\nconsidered copyrighted under the Copyright and Related Rights Act.\n\nAll rights reserved. Any use, reproduction or publication, in whole or in part, of the\ncontent of the Website is strictly prohibited. It is forbidden, without the express\nwritten consent of the owner, to use for commercial purposes and in any other way\neconomically exploit, either in whole or in part, the website or the content from the\nwebsite.\n"
        },
        cookies: {
            title: 'COOKIE POLICY',
            subtitle_1: 'This website uses cookies.',
            text_1: 'This Cookie Policy is intended to explain how Megga doo Pozega uses cookies, so please read this Noticecarefully before using www.megga.pro (the "Website").',
            subtitle_2: 'What are cookies?',
            text_2: "Cookies are simple text files stored on your device by an internet server when you visit a website.\nCookies allow us to recognize you and your browser the next time you visit the site. Each cookie is\nunique for each browser and will contain certain information such as domain names and various other\ninformation.",
            subtitle_3: "Which cookies do we use?",
            text_3: "Analytical cookies\n\nThese cookies allow us and third parties and their services to collect aggregated data for statistical\nanalysis of how our users use the Website. The cookies do not contain personal information about users\nsuch as names or e-mail and are used to improve the use of the website. This website uses Google Tag\nManager (Google Analytics) and Facebook Pixel cookies\n",
        },
        privacy: {
            title: 'PRIVACY POLICY',
            text_1: "Privacy Policy is dedicated to protecting the privacy of individuals who use and register at\nthe Megga doo Website www.megga.pro as defined below (''Clients'' or ''You'', ''Your''). The\nPrivacy Policy describes the Company's privacy practices regarding the use of the Company's\nwebsite and related applications and services offered by the Company. The privacy policy\nexplains how we process information we collect when you visit our site. It also describes\nyour choices regarding the use, access and correction of your private information. By using\nour site and our services, you understand and agree that we collect, store and process your\ninformation. If you do not agree to the terms of use explained in this Privacy Policy, you\nshould not use our website or Services.\n\nPrivacy Policy is very important so please read it carefully.\n\n",
            subtitle_2: "USE OF THE WEBSITE",
            text_2: "Information provided through the application and services, whether through website\nvisitors or when service users enter information, is stored on the servers of our service\nproviders. This information is further used to contact visitors and users regarding their\ninterests and interaction with the Company's services. We process information from our\nclients and store it on the servers of our service providers, but we do not have control over\ntheir collection or management. We agree that clients have the right to access their\nPersonal Information.\n",
            subtitle_3: "COLLECTED INFORMATION",
            text_3: "You are free to browse the Company's website without providing any private information\nabout yourself. When you register, we ask that you voluntarily provide us with personal\ninformation, such as your name, email address etc.\n",
            text_4: "The Company's website may contain links to other websites. Data practices or the content\nof other websites are governed by the privacy policies of those other websites.\n",
            text_5: "If you choose to withdraw your consent, you can do so at any time. Withdrawal of your\nconsent will not affect the lawfulness of any processing actions we have performed prior to\nyour withdrawal, nor will it affect the processing of your personal information that is being\nrealized and relies on legitimate processing grounds rather than consent.\n",
            text_6: "We can also collect data through the use of data collection tools such as cookies. Such\ninformation includes standard information from your web browser (such as the browser\ntype and browser language), your internet protocol (IP) address, and the actions you\nperform on Company website (such as web pages viewed). The information is used by the\nCompany to maintain the quality of services and to provide general statistics regarding the\nuse of the Company's website. See Cookie Policy for more information.\n",
            subtitle_4: "Copyright Notice",
            text_7: "The website and contents contained within are the sole property of Megga doo Pozega ©\n2020 Megga doo. All rights reserved.\n"
        },
        gdprWindow: {
            accept: 'Accept',
            info: 'More information'
        },
    },
    gdpr: {
        cookies: "COOKIE NOTIFICATION",
        privacy: "PRIVACY POLICY",
    },
    gallery: {
        all: 'ALL',
        mercedes: 'MERCEDES',
        iveco: 'IVECO'
    },
    trademark: '© Megga doo Požega, All rights reserved,\nDesign, Spin digital'
}

export default eng;

