import styled from 'styled-components';
import Zoom from 'react-reveal/Zoom';

export const TheWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const Container = styled.div`
margin-top: 35px;
display: inline-flex;
flex-wrap: nowrap;
align-self: center;
width: 1000px;
flex-direction: column;
display: -webkit-flex;
    display: -moz-flex;
    white-space: nowrap;

@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 500px;
}
@media only screen and (max-width: 510px) {
    /* width: 400px; */
    width: 100%;
}
@media only screen and (max-width: 400px) {
    /* width: 275px; */
    width: 100%;
}
`;
export const Column = styled.div`
display: flex;
flex-direction: column;
white-space: nowrap;
vertical-align: top;
margin-bottom: 50px;
/* background-color: khaki; */
width: 100%;
@media only screen and (max-width: 850px) {
    margin-bottom: 0px;
}
`;
export const Section = styled.div`
display: inline-block;
vertical-align: top;
width: 45%;
margin-right: ${ props => props.first ? '10%' : '0'};
white-space: nowrap;
@media only screen and (max-width: 850px) {
    width: 80%;
    margin-left: 10%;
    display: block;
    margin-bottom: 50px;
}

@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Inline = styled.div`
display: inline;
@media only screen and (max-width: 850px) {
    display: inline-block;
}
`;

export const Image = styled.img`
position: relative;

height: auto;
width: auto;
min-width: 100%;
max-width: 100%;
max-height: 300px;
`;

export const Content = styled.div`
width: 100%;
height: 150px;
background-color: yellow;
`;

export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 55px;
max-height: 3px;
margin-top: 8px;
border-bottom: 4px solid rgb(247,148,29);
/* experimental  */
color: black;

@media only screen and (max-width: 850px) {

}
`;
export const WrapperWrapper = styled.div`
width:100%;
display: flex;
flex-direction: column;
`;

export const ForReal = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;
export const TextContent = styled.div`
display: flex;
flex-direction: row;
align-self: center;
width: 100%;
@media only screen and (max-width: 850px) {
    width: 400px;
}
@media only screen and (max-width: 510px) {
    width: 350px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;

export const Title = styled.h2`
color: rgb(28, 120 ,187);
font-size: 31px;
white-space: nowrap;
width: auto;
font-weight: bold;
font-family: "Ubuntu";
@media only screen and (max-width: 850px) {
    
 font-size: 27px;   
 margin-top: 35px;
 margin-bottom: 35px;
 float: center;
 align-self: center;
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;