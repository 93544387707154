import React from 'react';

class Loader extends React.Component {
    render() {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        );
    }
}

export default Loader;