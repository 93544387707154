import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Container = styled.div`
width: 100%;
height: 40px;
background-color: #C4C4C4;
display: flex;
flex-direction: column;
justify-content: center;
@media only screen and (max-width: 800px) {
margin-top: 45px;
position: fixed;
z-index: 4;
}
`;

const Text = styled.p`
font-family: Ubuntu;
font-style: normal;
font-weight: bold;
font-size: 14px;
display: inline-block;
flex-direction: row;
margin-left: 15px;
color: ${ props => { if (props.selected === true) { return '#1C75BC'; } else { return '#FFFFFF'; } }};
:hover{
    cursor: pointer;
}
`;
const Inline = styled.div`
display: flex;
flex-direction: row;
align-self: center;
`;

const mapStateToProps = (state) => {
    return {
        labels: state.language.gallery
    }
}
class GalleryFilter extends React.Component {
    render() {
        return (
            <Container >
                <Inline>
                    <inline>
                        <Text onClick={() => this.props.onClick('all')} selected={this.props.selected === 'all' ? true : false}>{this.props.labels.all}</Text>
                        <Text onClick={() => this.props.onClick('iveco')} selected={this.props.selected === 'iveco' ? true : false}>{this.props.labels.iveco}</Text>
                        <Text onClick={() => this.props.onClick('mercedes')} selected={this.props.selected === 'mercedes' ? true : false}>{this.props.labels.mercedes}</Text>
                    </inline>
                </Inline>
            </Container>
        );
    }
}

export default connect(mapStateToProps, null)(GalleryFilter);