import HomeBackground from '../assets/background/home.jpg';
import HomeBackgroundMobile from '../assets/background/homeMobile.png';
import HomeImage_1 from '../assets/pictures/home/image1.jpg';
import HomeImage_2 from '../assets/pictures/home/image2.jpg';
import HomeImage_3 from '../assets/pictures/home/image3.jpg';
import HomeImage_4_eu_en from '../assets/pictures/home/map-en.jpg';
import HomeImage_4_eu_srb from '../assets/pictures/home/map-srb.jpg';
import HomeImage_4_eu_de from '../assets/pictures/home/map-de.jpg';
import certificate_1 from '../assets/pictures/home/image5.png';
import certificate_2 from '../assets/pictures/home/image6.png';
import certificate_3 from '../assets/pictures/home/image7.png';
import certificate_4 from '../assets/pictures/home/image8.png';
import certificate_5 from '../assets/pictures/home/image9.png';
import certificate_3_eng from '../assets/pictures/home/image7_eng.png';
import certificate_4_eng from '../assets/pictures/home/image8_eng.png';
import certificate_5_eng from '../assets/pictures/home/image9_eng.png';
import certificate_3_deu from '../assets/pictures/home/image7_deu.png';
import certificate_4_deu from '../assets/pictures/home/image8_deu.png';
import certificate_5_deu from '../assets/pictures/home/image9_deu.png';
import certificate_onHover from '../assets/pictures/home/hover.png'
import Facebook from '../assets/icon/facebook.png';
import scrollToTop from '../assets/pictures/home/totop.png';
import Magnifier from '../assets/icon/magnifier.png';

import HistoryBackground from '../assets/background/history.jpg';
import HistoryBackgroundMobile from '../assets/background/historyMobile.png';

import ContactBackground from '../assets/background/contact.jpg';
import ContactBackgroundMobile from '../assets/background/contactMobile.jpg';

import godown from '../assets/icon/godown.png';
import DownArrowMenu from '../assets/icon/strelica.png';
import ArrowRight from '../assets/icon/arrowLeft.png';
import Close from '../assets/icon/close.png';

// Gallery
import GalleryImage_01_large from '../assets/pictures/gallery/large/1-iveco.jpg';
import GalleryImage_01_small from '../assets/pictures/gallery/small/1-iveco.jpg';

import GalleryImage_02_large from '../assets/pictures/gallery/large/2-iveco.jpg';
import GalleryImage_02_small from '../assets/pictures/gallery/small/2-iveco.jpg';

import GalleryImage_03_large from '../assets/pictures/gallery/large/3-iveco-mercedes.jpg';
import GalleryImage_03_small from '../assets/pictures/gallery/small/3-iveco-mercedes.jpg';

import GalleryImage_04_large from '../assets/pictures/gallery/large/4-iveco.jpg';
import GalleryImage_04_small from '../assets/pictures/gallery/small/4-iveco.jpg';

import GalleryImage_05_large from '../assets/pictures/gallery/large/5-iveco-mercedes.jpg';
import GalleryImage_05_small from '../assets/pictures/gallery/small/5-iveco-mercedes.jpg';

import GalleryImage_06_large from '../assets/pictures/gallery/large/6-iveco.jpg';
import GalleryImage_06_small from '../assets/pictures/gallery/small/6-iveco.jpg';

import GalleryImage_07_large from '../assets/pictures/gallery/large/7-iveco-mercedes.jpg';
import GalleryImage_07_small from '../assets/pictures/gallery/small/7-iveco-mercedes.jpg';

import GalleryImage_08_large from '../assets/pictures/gallery/large/8-iveco-mercedes.jpg';
import GalleryImage_08_small from '../assets/pictures/gallery/small/8-iveco-mercedes.jpg';

import GalleryImage_09_large from '../assets/pictures/gallery/large/9-iveco.jpg';
import GalleryImage_09_small from '../assets/pictures/gallery/small/9-iveco.jpg';

import GalleryImage_10_large from '../assets/pictures/gallery/large/10-iveco.jpg';
import GalleryImage_10_small from '../assets/pictures/gallery/small/10-iveco.jpg';

import GalleryImage_11_large from '../assets/pictures/gallery/large/11-mercedes.jpg';
import GalleryImage_11_small from '../assets/pictures/gallery/small/11-mercedes.jpg';

import GalleryImage_12_large from '../assets/pictures/gallery/large/12-iveco.jpg';
import GalleryImage_12_small from '../assets/pictures/gallery/small/12-iveco.jpg';

import GalleryImage_13_large from '../assets/pictures/gallery/large/13-iveco-mercedes.jpg';
import GalleryImage_13_small from '../assets/pictures/gallery/small/13-iveco-mercedes.jpg';

import GalleryImage_14_large from '../assets/pictures/gallery/large/14-iveco.jpg';
import GalleryImage_14_small from '../assets/pictures/gallery/small/14-iveco.jpg';

import GalleryImage_15_large from '../assets/pictures/gallery/large/15-iveco.jpg';
import GalleryImage_15_small from '../assets/pictures/gallery/small/15-iveco.jpg';

import GalleryImage_16_large from '../assets/pictures/gallery/large/16-mercedes.jpg';
import GalleryImage_16_small from '../assets/pictures/gallery/small/16-mercedes.jpg';

import GalleryImage_17_large from '../assets/pictures/gallery/large/17-iveco.jpg';
import GalleryImage_17_small from '../assets/pictures/gallery/small/17-iveco.jpg';

import GalleryImage_18_large from '../assets/pictures/gallery/large/18-iveco-mercedes.jpg';
import GalleryImage_18_small from '../assets/pictures/gallery/small/18-iveco-mercedes.jpg';

import GalleryImage_19_large from '../assets/pictures/gallery/large/19-iveco.jpg';
import GalleryImage_19_small from '../assets/pictures/gallery/small/19-iveco.jpg';

import GalleryImage_20_large from '../assets/pictures/gallery/large/20-iveco.jpg';
import GalleryImage_20_small from '../assets/pictures/gallery/small/20-iveco.jpg';

import GalleryImage_21_large from '../assets/pictures/gallery/large/21-mercedes.jpg';
import GalleryImage_21_small from '../assets/pictures/gallery/small/21-mercedes.jpg';

import GalleryImage_22_large from '../assets/pictures/gallery/large/22-iveco-mercedes.jpg';
import GalleryImage_22_small from '../assets/pictures/gallery/small/22-iveco-mercedes.jpg';

import GalleryImage_23_large from '../assets/pictures/gallery/large/23-iveco.jpg';
import GalleryImage_23_small from '../assets/pictures/gallery/small/23-iveco.jpg';

import GalleryImage_24_large from '../assets/pictures/gallery/large/24-iveco.jpg';
import GalleryImage_24_small from '../assets/pictures/gallery/small/24-iveco.jpg';

import GalleryImage_25_large from '../assets/pictures/gallery/large/25-mercedes.jpg';
import GalleryImage_25_small from '../assets/pictures/gallery/small/25-mercedes.jpg';

import GalleryImage_26_large from '../assets/pictures/gallery/large/26-iveco-mercedes.jpg';
import GalleryImage_26_small from '../assets/pictures/gallery/small/26-iveco-mercedes.jpg';

import GalleryImage_27_large from '../assets/pictures/gallery/large/27-iveco.jpg';
import GalleryImage_27_small from '../assets/pictures/gallery/small/27-iveco.jpg';

import GalleryImage_28_large from '../assets/pictures/gallery/large/28-iveco-mercedes.jpg';
import GalleryImage_28_small from '../assets/pictures/gallery/small/28-iveco-mercedes.jpg';

import GalleryImage_29_large from '../assets/pictures/gallery/large/29-mercedes.jpg';
import GalleryImage_29_small from '../assets/pictures/gallery/small/29-mercedes.jpg';

import GalleryImage_30_large from '../assets/pictures/gallery/large/30-iveco-mercedes.jpg';
import GalleryImage_30_small from '../assets/pictures/gallery/small/30-iveco-mercedes.jpg';

import GalleryImage_31_large from '../assets/pictures/gallery/large/31-iveco.jpg';
import GalleryImage_31_small from '../assets/pictures/gallery/small/31-iveco.jpg';

import GalleryImage_32_large from '../assets/pictures/gallery/large/32-iveco-mercedes.jpg';
import GalleryImage_32_small from '../assets/pictures/gallery/small/32-iveco-mercedes.jpg';

import GalleryImage_33_large from '../assets/pictures/gallery/large/33-iveco.jpg';
import GalleryImage_33_small from '../assets/pictures/gallery/small/33-iveco.jpg';

import GalleryImage_34_large from '../assets/pictures/gallery/large/34-iveco-mercedes.jpg';
import GalleryImage_34_small from '../assets/pictures/gallery/small/34-iveco-mercedes.jpg';

import GalleryImage_35_large from '../assets/pictures/gallery/large/35-iveco-mercedes.jpg';
import GalleryImage_35_small from '../assets/pictures/gallery/small/35-iveco-mercedes.jpg';

import GalleryImage_36_large from '../assets/pictures/gallery/large/36-iveco.jpg';
import GalleryImage_36_small from '../assets/pictures/gallery/small/36-iveco.jpg';

import GalleryImage_37_large from '../assets/pictures/gallery/large/37-iveco.jpg';
import GalleryImage_37_small from '../assets/pictures/gallery/small/37-iveco.jpg';

import GalleryImage_38_large from '../assets/pictures/gallery/large/38-iveco-mercedes.jpg';
import GalleryImage_38_small from '../assets/pictures/gallery/small/38-iveco-mercedes.jpg';

import GalleryImage_39_large from '../assets/pictures/gallery/large/39-iveco.jpg';
import GalleryImage_39_small from '../assets/pictures/gallery/small/39-iveco.jpg';

import GalleryImage_40_large from '../assets/pictures/gallery/large/40-iveco.jpg';
import GalleryImage_40_small from '../assets/pictures/gallery/small/40-iveco.jpg';

import GalleryImage_41_large from '../assets/pictures/gallery/large/41-iveco.jpg';
import GalleryImage_41_small from '../assets/pictures/gallery/small/41-iveco.jpg';

import GalleryImage_42_large from '../assets/pictures/gallery/large/42-iveco.jpg';
import GalleryImage_42_small from '../assets/pictures/gallery/small/42-iveco.jpg';

import GalleryImage_43_large from '../assets/pictures/gallery/large/43-iveco.jpg';
import GalleryImage_43_small from '../assets/pictures/gallery/small/43-iveco.jpg';

import GalleryImage_44_large from '../assets/pictures/gallery/large/44-iveco-mercedes.jpg';
import GalleryImage_44_small from '../assets/pictures/gallery/small/44-iveco-mercedes.jpg';

import GalleryImage_45_large from '../assets/pictures/gallery/large/45-iveco.jpg';
import GalleryImage_45_small from '../assets/pictures/gallery/small/45-iveco.jpg';

import GalleryImage_46_large from '../assets/pictures/gallery/large/46-iveco-mercedes.jpg';
import GalleryImage_46_small from '../assets/pictures/gallery/small/46-iveco-mercedes.jpg';

import GalleryImage_47_large from '../assets/pictures/gallery/large/47-iveco.jpg';
import GalleryImage_47_small from '../assets/pictures/gallery/small/47-iveco.jpg';

import GalleryImage_48_large from '../assets/pictures/gallery/large/48-iveco-mercedes.jpg';
import GalleryImage_48_small from '../assets/pictures/gallery/small/48-iveco-mercedes.jpg';

import GalleryImage_49_large from '../assets/pictures/gallery/large/49-iveco.jpg';
import GalleryImage_49_small from '../assets/pictures/gallery/small/49-iveco.jpg';

import GalleryImage_50_large from '../assets/pictures/gallery/large/50-iveco-mercedes.jpg';
import GalleryImage_50_small from '../assets/pictures/gallery/small/50-iveco-mercedes.jpg';

import GalleryImage_51_large from '../assets/pictures/gallery/large/51-iveco.jpg';
import GalleryImage_51_small from '../assets/pictures/gallery/small/51-iveco.jpg';

import GalleryImage_52_large from '../assets/pictures/gallery/large/52-iveco.jpg';
import GalleryImage_52_small from '../assets/pictures/gallery/small/52-iveco.jpg';

import GalleryImage_53_large from '../assets/pictures/gallery/large/53-iveco.jpg';
import GalleryImage_53_small from '../assets/pictures/gallery/small/53-iveco.jpg';

import GalleryImage_54_large from '../assets/pictures/gallery/large/54-iveco-mercedes.jpg';
import GalleryImage_54_small from '../assets/pictures/gallery/small/54-iveco-mercedes.jpg';

import GalleryImage_55_large from '../assets/pictures/gallery/large/55-iveco-mercedes.jpg';
import GalleryImage_55_small from '../assets/pictures/gallery/small/55-iveco-mercedes.jpg';

import GalleryImage_56_large from '../assets/pictures/gallery/large/56-iveco-mercedes.jpg';
import GalleryImage_56_small from '../assets/pictures/gallery/small/56-iveco-mercedes.jpg';

import GalleryImage_57_large from '../assets/pictures/gallery/large/57-iveco.jpg';
import GalleryImage_57_small from '../assets/pictures/gallery/small/57-iveco.jpg';

import GalleryImage_58_large from '../assets/pictures/gallery/large/58-iveco-mercedes.jpg';
import GalleryImage_58_small from '../assets/pictures/gallery/small/58-iveco-mercedes.jpg';

import GalleryImage_59_large from '../assets/pictures/gallery/large/59-iveco.jpg';
import GalleryImage_59_small from '../assets/pictures/gallery/small/59-iveco.jpg';

import GalleryImage_60_large from '../assets/pictures/gallery/large/60-iveco.jpg';
import GalleryImage_60_small from '../assets/pictures/gallery/small/60-iveco.jpg';

import GalleryImage_61_large from '../assets/pictures/gallery/large/61-mercedes.jpg';
import GalleryImage_61_small from '../assets/pictures/gallery/small/61-mercedes.jpg';

import GalleryImage_62_large from '../assets/pictures/gallery/large/62-iveco-mercedes.jpg';
import GalleryImage_62_small from '../assets/pictures/gallery/small/62-iveco-mercedes.jpg';

import GalleryImage_63_large from '../assets/pictures/gallery/large/63-iveco.jpg';
import GalleryImage_63_small from '../assets/pictures/gallery/small/63-iveco.jpg';

import GalleryImage_64_large from '../assets/pictures/gallery/large/64-iveco-mercedes.jpg';
import GalleryImage_64_small from '../assets/pictures/gallery/small/64-iveco-mercedes.jpg';

import GalleryImage_65_large from '../assets/pictures/gallery/large/65-iveco.jpg';
import GalleryImage_65_small from '../assets/pictures/gallery/small/65-iveco.jpg';

import GalleryImage_66_large from '../assets/pictures/gallery/large/66-iveco-mercedes.jpg';
import GalleryImage_66_small from '../assets/pictures/gallery/small/66-iveco-mercedes.jpg';

import GalleryImage_67_large from '../assets/pictures/gallery/large/67-iveco.jpg';
import GalleryImage_67_small from '../assets/pictures/gallery/small/67-iveco.jpg';

import GalleryImage_68_large from '../assets/pictures/gallery/large/68-iveco-mercedes.jpg';
import GalleryImage_68_small from '../assets/pictures/gallery/small/68-iveco-mercedes.jpg';

import GalleryImage_69_large from '../assets/pictures/gallery/large/69-iveco.jpg';
import GalleryImage_69_small from '../assets/pictures/gallery/small/69-iveco.jpg';

import GalleryImage_70_large from '../assets/pictures/gallery/large/70-iveco-mercedes.jpg';
import GalleryImage_70_small from '../assets/pictures/gallery/small/70-iveco-mercedes.jpg';





const images = {
    home: {
        background: HomeBackground,
        backgroundMobile: HomeBackgroundMobile,
        image_1: HomeImage_1,
        image_2: HomeImage_2,
        image_3: HomeImage_3,
        image_4_europe_en: HomeImage_4_eu_en,
        image_4_europe_de: HomeImage_4_eu_de,
        image_4_europe_srb: HomeImage_4_eu_srb,
        certificate_1,
        certificate_2,
        certificate_3,
        certificate_4,
        certificate_5,
        certificate_3_eng,
        certificate_4_eng,
        certificate_5_eng,
        certificate_3_deu,
        certificate_4_deu,
        certificate_5_deu,
        certificate_onHover,
        facebook: Facebook,
        scrollToTop,
    },
    history: {
        background: HistoryBackground,
        backgroundMobile: HistoryBackgroundMobile
    },
    contact: {
        background: ContactBackground,
        backgroundMobile: ContactBackgroundMobile
    },
    gallery: [
        {
            id: 1,
            type: 'iveco',
            small: GalleryImage_01_small,
            large: GalleryImage_01_large,
        },
        {
            id: 2,
            type: 'iveco',
            small: GalleryImage_02_small,
            large: GalleryImage_02_large,
        },
        {
            id: 3,
            type: 'both',
            small: GalleryImage_03_small,
            large: GalleryImage_03_large,
        },
        {
            id: 4,
            type: 'iveco',
            small: GalleryImage_04_small,
            large: GalleryImage_04_large,
        },
        {
            id: 5,
            type: 'both',
            small: GalleryImage_05_small,
            large: GalleryImage_05_large,
        },
        {
            id: 6,
            type: 'iveco',
            small: GalleryImage_06_small,
            large: GalleryImage_06_large,
        },
        {
            id: 7,
            type: 'both',
            small: GalleryImage_07_small,
            large: GalleryImage_07_large,
        },
        {
            id: 8,
            type: 'both',
            small: GalleryImage_08_small,
            large: GalleryImage_08_large,
        },
        {
            id: 9,
            type: 'iveco',
            small: GalleryImage_09_small,
            large: GalleryImage_09_large,
        },
        {
            id: 10,
            type: 'iveco',
            small: GalleryImage_10_small,
            large: GalleryImage_10_large,
        },
        {
            id: 11,
            type: 'mercedes',
            small: GalleryImage_11_small,
            large: GalleryImage_11_large,
        },
        {
            id: 12,
            type: 'iveco',
            small: GalleryImage_12_small,
            large: GalleryImage_12_large,
        },
        {
            id: 13,
            type: 'both',
            small: GalleryImage_13_small,
            large: GalleryImage_13_large,
        },
        {
            id: 14,
            type: 'iveco',
            small: GalleryImage_14_small,
            large: GalleryImage_14_large,
        },
        {
            id: 15,
            type: 'iveco',
            small: GalleryImage_15_small,
            large: GalleryImage_15_large,
        },
        {
            id: 16,
            type: 'mercedes',
            small: GalleryImage_16_small,
            large: GalleryImage_16_large,
        },
        {
            id: 17,
            type: 'iveco',
            small: GalleryImage_17_small,
            large: GalleryImage_17_large,
        },
        {
            id: 18,
            type: 'both',
            small: GalleryImage_18_small,
            large: GalleryImage_18_large,
        },
        {
            id: 19,
            type: 'iveco',
            small: GalleryImage_19_small,
            large: GalleryImage_19_large,
        },
        {
            id: 20,
            type: 'iveco',
            small: GalleryImage_20_small,
            large: GalleryImage_20_large,
        },
        {
            id: 21,
            type: 'mercedes',
            small: GalleryImage_21_small,
            large: GalleryImage_21_large,
        },
        {
            id: 22,
            type: 'both',
            small: GalleryImage_22_small,
            large: GalleryImage_22_large,
        },
        {
            id: 23,
            type: 'iveco',
            small: GalleryImage_23_small,
            large: GalleryImage_23_large,
        },
        {
            id: 24,
            type: 'iveco',
            small: GalleryImage_24_small,
            large: GalleryImage_24_large,
        },
        {
            id: 25,
            type: 'mercedes',
            small: GalleryImage_25_small,
            large: GalleryImage_25_large,
        },
        {
            id: 26,
            type: 'both',
            small: GalleryImage_26_small,
            large: GalleryImage_26_large,
        },
        {
            id: 27,
            type: 'iveco',
            small: GalleryImage_27_small,
            large: GalleryImage_27_large,
        },
        {
            id: 28,
            type: 'both',
            small: GalleryImage_28_small,
            large: GalleryImage_28_large,
        },
        {
            id: 29,
            type: 'mercedes',
            small: GalleryImage_29_small,
            large: GalleryImage_29_large,
        },
        {
            id: 30,
            type: 'both',
            small: GalleryImage_30_small,
            large: GalleryImage_30_large,
        },
        {
            id: 31,
            type: 'iveco',
            small: GalleryImage_31_small,
            large: GalleryImage_31_large,
        },
        {
            id: 32,
            type: 'both',
            small: GalleryImage_32_small,
            large: GalleryImage_32_large,
        },
        {
            id: 33,
            type: 'iveco',
            small: GalleryImage_33_small,
            large: GalleryImage_25_large,
        },
        {
            id: 34,
            type: 'both',
            small: GalleryImage_34_small,
            large: GalleryImage_34_large,
        },
        {
            id: 35,
            type: 'both',
            small: GalleryImage_35_small,
            large: GalleryImage_35_large,
        },
        {
            id: 36,
            type: 'iveco',
            small: GalleryImage_36_small,
            large: GalleryImage_36_large,
        },
        {
            id: 37,
            type: 'iveco',
            small: GalleryImage_37_small,
            large: GalleryImage_37_large,
        },
        {
            id: 38,
            type: 'both',
            small: GalleryImage_38_small,
            large: GalleryImage_38_large,
        },
        {
            id: 39,
            type: 'iveco',
            small: GalleryImage_39_small,
            large: GalleryImage_39_large,
        },
        {
            id: 40,
            type: 'iveco',
            small: GalleryImage_40_small,
            large: GalleryImage_40_large,
        },
        {
            id: 41,
            type: 'iveco',
            small: GalleryImage_41_small,
            large: GalleryImage_41_large,
        },
        {
            id: 42,
            type: 'iveco',
            small: GalleryImage_42_small,
            large: GalleryImage_42_large,
        },
        {
            id: 43,
            type: 'iveco',
            small: GalleryImage_43_small,
            large: GalleryImage_43_large,
        },
        {
            id: 44,
            type: 'both',
            small: GalleryImage_44_small,
            large: GalleryImage_44_large,
        },
        {
            id: 45,
            type: 'iveco',
            small: GalleryImage_45_small,
            large: GalleryImage_45_large,
        },
        {
            id: 46,
            type: 'both',
            small: GalleryImage_46_small,
            large: GalleryImage_46_large,
        },
        {
            id: 47,
            type: 'iveco',
            small: GalleryImage_47_small,
            large: GalleryImage_47_large,
        },
        {
            id: 48,
            type: 'both',
            small: GalleryImage_48_small,
            large: GalleryImage_48_large,
        },
        {
            id: 49,
            type: 'iveco',
            small: GalleryImage_49_small,
            large: GalleryImage_49_large,
        },
        {
            id: 50,
            type: 'both',
            small: GalleryImage_50_small,
            large: GalleryImage_50_large,
        },
        {
            id: 51,
            type: 'iveco',
            small: GalleryImage_51_small,
            large: GalleryImage_51_large,
        },
        {
            id: 52,
            type: 'iveco',
            small: GalleryImage_52_small,
            large: GalleryImage_52_large,
        },
        {
            id: 53,
            type: 'iveco',
            small: GalleryImage_53_small,
            large: GalleryImage_53_large,
        },
        {
            id: 54,
            type: 'both',
            small: GalleryImage_54_small,
            large: GalleryImage_54_large,
        },
        {
            id: 55,
            type: 'both',
            small: GalleryImage_55_small,
            large: GalleryImage_55_large,
        },
        {
            id: 56,
            type: 'both',
            small: GalleryImage_56_small,
            large: GalleryImage_56_large,
        },
        {
            id: 57,
            type: 'iveco',
            small: GalleryImage_57_small,
            large: GalleryImage_57_large,
        },
        {
            id: 58,
            type: 'both',
            small: GalleryImage_58_small,
            large: GalleryImage_58_large,
        },
        {
            id: 59,
            type: 'iveco',
            small: GalleryImage_59_small,
            large: GalleryImage_59_large,
        },
        {
            id: 60,
            type: 'iveco',
            small: GalleryImage_60_small,
            large: GalleryImage_60_large,
        },
        {
            id: 61,
            type: 'mercedes',
            small: GalleryImage_61_small,
            large: GalleryImage_61_large,
        },
        {
            id: 62,
            type: 'both',
            small: GalleryImage_62_small,
            large: GalleryImage_62_large,
        },
        {
            id: 63,
            type: 'iveco',
            small: GalleryImage_63_small,
            large: GalleryImage_63_large,
        },
        {
            id: 64,
            type: 'both',
            small: GalleryImage_64_small,
            large: GalleryImage_64_large,
        },
        {
            id: 65,
            type: 'iveco',
            small: GalleryImage_65_small,
            large: GalleryImage_65_large,
        },
        {
            id: 66,
            type: 'both',
            small: GalleryImage_66_small,
            large: GalleryImage_66_large,
        },
        {
            id: 67,
            type: 'iveco',
            small: GalleryImage_67_small,
            large: GalleryImage_67_large,
        },
        {
            id: 68,
            type: 'both',
            small: GalleryImage_68_small,
            large: GalleryImage_68_large,
        },
        {
            id: 69,
            type: 'iveco',
            small: GalleryImage_69_small,
            large: GalleryImage_69_large,
        },
        {
            id: 70,
            type: 'both',
            small: GalleryImage_70_small,
            large: GalleryImage_70_large,
        },
    ],
    general: {
        goDownArrow: godown,
        DownArrowMenu,
        ArrowRight,
        Magnifier,
        Close
    }
}

export default images;