import React from 'react';

import { PageContainer, Paragraph, BlueText } from '../../styles';
import Jumbotron from '../../components/Jumbotron';
import Config from '../../config';
import MainSection from '../../components/MainSection';
import HistoryContent from '../../components/HistoryContent';
import OrangeBox from '../../components/OrangeBox';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import ScrollToTop from '../../components/ScrollToTop';
import CookieModal from '../../components/CookiesModal';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { detect } from 'detect-browser';

const mapStateToProps = (state) => {
    return {
        text: state.language,
        cookies: state.firstTime
    }
}

const FullWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

class History extends React.Component {
    constructor(props) {
        super(props);
        this.jumbotron = React.createRef();
    }

    state = {
        browser: ''
    }
    scrollToTop() {
        if (this.jumbotron.current) {
            this.jumbotron.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end"
                }
            )
        }
    }
    componentDidMount() {
        const browser = detect();
        if (browser) {
            if (browser.name === 'safari ') {
                this.setState({ ...this.state, browser: 'safari' })
            }
            else {
                this.setState({ ...this.state, browser: '' })

            }
        }
        if (this.jumbotron.current) {
            this.jumbotron.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <div ref={this.jumbotron}></div>
                {
                    this.state.browser !== 'safari' ?
                        <Zoom><OrangeBox text={this.props.text.history.orangeBox} leftCorner={true} germanMod={this.props.text.lang === 'en' ? true : false} /></Zoom>
                        :
                        <OrangeBox text={this.props.text.history.orangeBox} leftCorner={true} germanMod={this.props.text.lang === 'en' ? true : false} />

                }
                <Jumbotron background={Config.images.history.background} backgroundMobile={Config.images.history.backgroundMobile} />
                {
                    this.props.text && this.state.browser !== 'safari' ?
                        <>
                            <CookieModal show={this.props.cookies} />
                            <Fade right>
                                <FullWrapper >
                                    <MainSection title={this.props.text.history.history}>
                                        <Paragraph>{this.props.text.history.historyText}</Paragraph>
                                        <Paragraph>{this.props.text.history.historyText2}<BlueText>{this.props.text.history.moto}</BlueText></Paragraph>
                                        <Paragraph>{this.props.text.history.historyText3}</Paragraph>
                                    </MainSection>
                                </FullWrapper>
                            </Fade>
                            <Fade clear>
                                <FullWrapper>
                                    <HistoryContent />
                                </FullWrapper>
                            </Fade>
                            <Fade bottom>
                                <PrivacyPolicy />
                            </Fade>
                            <Zoom>
                                <ScrollToTop onClick={() => this.scrollToTop()} />
                            </Zoom>
                        </>
                        :
                        <>
                            {
                                this.props.text && this.state.browser === 'safari' ?
                                    <>
                                        <CookieModal show={this.props.cookies} />
                                        <FullWrapper >
                                            <MainSection title={this.props.text.history.history}>
                                                <Paragraph>{this.props.text.history.historyText}</Paragraph>
                                                <Paragraph>{this.props.text.history.historyText2}<BlueText>{this.props.text.history.moto}</BlueText></Paragraph>
                                                <Paragraph>{this.props.text.history.historyText3}</Paragraph>
                                            </MainSection>
                                        </FullWrapper>
                                        <FullWrapper>
                                            <HistoryContent />
                                        </FullWrapper>
                                        <PrivacyPolicy />
                                        <ScrollToTop onClick={() => this.scrollToTop()} />
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                }
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, null)(History);