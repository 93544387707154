
function startScroll() {
    return {
        type: 'START_SCROLL',
        scroll: true
    };
}

function endScroll() {
    return {
        type: 'END_SCROLL',
        scroll: false
    };
}

const scroll = { startScroll, endScroll };

export default scroll;