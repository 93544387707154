import styled from 'styled-components';

export const Container = styled.div`
width: 1000px;
display: flex;
flex-direction: column;
align-self: center;
min-height: 100vh;
/* flex-wrap: nowrap; */
display: -webkit-flex;
    display: -moz-flex;
    /* white-space: nowrap; */

@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 100%;
    margin-top: 60px;
}
@media only screen and (max-width: 510px) {
    /* width: 400px; */
    width: 100%;
}
@media only screen and (max-width: 400px) {
    /* width: 275px; */
    width: 100%;
}
`;

export const FuckingImage = styled.img`
width: 100%;
height: auto;
margin-bottom: -4px;
`;
export const ImageContainer = styled.div`
width: 33.3%;
/* background-image: url(${ props => props.image}); */
align-self: flex-start;
height: auto;
margin-bottom: -4px;
display: inline-block;
flex-direction: row;
@media only screen and (max-width: 1250px) {
    /* height: 280px; */
}
@media only screen and (max-width: 850px) {
     width: 50%; 
    /* height: 350px;
    background-size: 100% 100%;
    background-repeat: no-repeat; */ 
}
@media only screen and (max-width: 710px) {
/* height: 290px; */
}
@media only screen and (max-width: 565px) {
    /* background-size: auto 100%; */
    /* height: 250px; */
}
@media only screen and (max-width: 400px) {
    /* height: 220px; */
}

`;
export const ImageView = styled.div`
margin-top: 0px;
/* display: flex; */
flex-direction: column;
width:100%;
position: relative;
justify-content: center;
height: 100%;
transition: background-color 2s;
display: ${ props => { if (props.show === true) { return "block" } else { return "block"; } }};
:hover{
    cursor: pointer;
}
`;
export const Cmon = styled.div`
width: auto;
height: auto;
display: inline-block;
position: relative;
background-color: rgba(227, 131, 14, 0.8);
`;
export const Cmon2 = styled.div`
width: 100%;
height: 100%;
position: absolute;
background-color: rgba(227, 131, 14, 0.8);
`;
export const Inline = styled.div`
display: inline;
`;
export const MagnifierImage = styled.img`
display: flex;
flex-direction: row;
align-self: center;
vertical-align: center;
position: absolute;
margin-top: 130px;
margin-left: 46%;
width: 45px;
height: 45px;
z-index: 1;
@media only screen and (max-width: 1250px) {
    margin-top: 110px;
}
`;