import React from 'react';

import { PageContainer, Paragraph } from '../../styles';
import Config from '../../config';
import Jumbotron from '../../components/Jumbotron';
import MainSection from '../../components/MainSection';
import AboutContent from '../../components/AboutContent';
import Certificate from '../../components/Certificate';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Reviews from '../../components/Reviews';
import CookieModal from '../../components/CookiesModal';

import { connect } from 'react-redux';
import MapImage from '../../components/MapImage';
import OrangeBox from '../../components/OrangeBox';
import Actions from '../../store/Actions';
import CertificateList from '../../components/CertificateList';
import ScrollToTop from '../../components/ScrollToTop';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Loader from '../../components/Loader';
import { detect } from 'detect-browser';

import styled from 'styled-components';

const FullWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Home extends React.Component {

    constructor(props) {
        super(props);
        this.aboutContent = React.createRef();
        this.certificate = React.createRef();
        this.eventSlider = React.createRef();
        this.jumbotron = React.createRef();

    }

    state = {
        browser: ''
    }

    componentDidUpdate(prevState) {
        if (this.props.scroll === true) {
            let setUrl = '/';
            let url = window.location.toString();
            if (url.includes('about')) {
                this.handleScroll('about');
                setUrl = 'about';
            }
            if (url.includes('certificate')) {
                this.handleScroll('certificate');
                setUrl = 'certificate';
            }
        }
    }

    componentDidMount() {

        const browser = detect();
        if (browser) {
            if (browser.name === 'safari ') {
                this.setState({ ...this.state, browser: 'safari' })
            }
            else {
            }
        }
        let setUrl = '/';
        let url = window.location.toString();
        if (url.includes('about')) {
            this.handleScroll('about');
            setUrl = 'about';
        }
        if (url.includes('certificate')) {
            this.handleScroll('certificate');
            setUrl = 'certificate';
        }
        if (!url.includes('certificate') && !url.includes('about')) {
            if (this.jumbotron.current) {
                this.jumbotron.current.scrollIntoView(
                    {
                        behavior: "instant",
                        block: "end"
                    }
                )
            }
        }

        // this.setState({ ...this.state, firstpress: true });
    }

    handleScroll = (part) => {

        if (part === 'about') {
            if (this.aboutContent.current) {
                this.aboutContent.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }
        }
        if (part === 'certificate') {
            if (this.certificate.current) {
                this.certificate.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }
        }
        if (part !== 'certificate' && part !== 'about') {
            window.location.scrollTo(0, 0);
        }
        this.props.endScroll();
    }
    scrollToTop() {
        if (this.jumbotron.current) {
            this.jumbotron.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end"
                }
            )
        }
    }
    // handleScroll = () => window.scrollTo(0, this.aboutContent.current.offsetTop);

    render() {
        return (
            <PageContainer>
                <div ref={this.jumbotron} ></div>

                {
                    this.state.browser !== 'safari' ?
                        <Zoom>
                            <OrangeBox
                                text={this.props.text.about.orangeBox}
                                germanMod={this.props.text.lang == 'de' ? true : false}
                                closeToTop={true}
                                lastHack={this.props.text.lang == 'de' ? true : false}
                            />
                        </Zoom>
                        :
                        <OrangeBox
                            text={this.props.text.about.orangeBox}
                            germanMod={this.props.text.lang == 'de' ? true : false}
                            closeToTop={true}
                            lastHack={this.props.text.lang == 'de' ? true : false}
                        />
                }

                <Jumbotron
                    background={Config.images.home.background}
                    backgroundMobile={Config.images.home.backgroundMobile}
                />
                {

                    this.props.text && this.state.browser !== 'safari' ?
                        <>
                            <CookieModal show={this.props.firstTimeVar} />
                            <div ref={this.aboutContent}></div>
                            <Fade left>
                                <FullWrapper>
                                    <MainSection title={this.props.text.about.about}>
                                        <Paragraph>{this.props.text.about.about_text_1}</Paragraph>
                                        <Paragraph>{this.props.text.about.about_text_2}</Paragraph>
                                    </MainSection>
                                </FullWrapper>
                            </Fade>
                            <Fade right>
                                <FullWrapper>
                                    <AboutContent />
                                </FullWrapper>
                            </Fade>
                            <div ref={this.certificate}></div>
                            <Fade bottom>
                                <FullWrapper>
                                    <Certificate />

                                </FullWrapper>
                            </Fade>
                            <FullWrapper>
                                <Fade right>
                                    <CertificateList />
                                </Fade>
                            </FullWrapper>
                            <Fade clear>
                                <FullWrapper>
                                    <MainSection title={this.props.text.about.locations_title}>
                                        <Paragraph>{this.props.text.about.locations_text}</Paragraph>
                                    </MainSection>
                                </FullWrapper>
                            </Fade>
                            <Fade clear>
                                <MapImage lang={this.props.text.lang} />
                            </Fade>
                            {/* <Fade left> */}
                            <Reviews />
                            {/* </Fade> */}
                            {/* <Fade bottom> */}
                            <PrivacyPolicy />
                            {/* </Fade> */}
                            <Fade right>
                                <ScrollToTop onClick={() => this.scrollToTop()} />
                            </Fade>
                        </>
                        :
                        <>
                            {
                                this.props.text && this.state.browser === 'safari' ?
                                    <>
                                        <CookieModal show={this.props.firstTimeVar} />
                                        <div ref={this.aboutContent}></div>
                                        <FullWrapper>
                                            <MainSection title={this.props.text.about.about}>
                                                <Paragraph>{this.props.text.about.about_text_1}</Paragraph>
                                                <Paragraph>{this.props.text.about.about_text_2}</Paragraph>
                                            </MainSection>
                                        </FullWrapper>
                                        <FullWrapper>
                                            <AboutContent />
                                        </FullWrapper>
                                        <div ref={this.certificate}></div>
                                        <FullWrapper>
                                            <Certificate />

                                        </FullWrapper>
                                        <FullWrapper>
                                            <CertificateList />
                                        </FullWrapper>
                                        <FullWrapper>
                                            <MainSection title={this.props.text.about.locations_title}>
                                                <Paragraph>{this.props.text.about.locations_text}</Paragraph>
                                            </MainSection>
                                        </FullWrapper>
                                        <MapImage lang={this.props.text.lang} />
                                        <Reviews />
                                        <PrivacyPolicy />
                                        <ScrollToTop onClick={() => this.scrollToTop()} />
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                }
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);