import React from 'react';
import styled from 'styled-components';
import Config from '../../config';
import ScrollToTopImage from '../../assets/pictures/home/totop.png';

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 30px;
overflow: hidden;
`;
const Image = styled.img`
margin-right: 15%;
border-radius: 10px;
display: flex;
align-self: flex-end;
width: auto;
height: auto;
cursor: pointer;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}
@media only screen and (max-width: 850px) {
    margin-right: 15px;
}
`;

class ScrollToTop extends React.Component {
    render() {
        return (
            <Container onClick={this.props.onClick}>
                <Image src={ScrollToTopImage} />
            </Container>
        )
    }
}

export default ScrollToTop;