import React from 'react';
import { Container, Column, InlineIcon, Icon } from './styled';
import Config from '../../config';
import { connect } from 'react-redux';

const mapDispatchToProps = (state) => {
    return {
        lang: state.language.lang
    }
}
class CertificateList extends React.Component {


    render() {
        return (
            <Container>
                <Column>
                    <InlineIcon>
                        <a href={Config.pdf.home.certificate_1_pdf} download>
                            <Icon src={Config.images.home.certificate_1} />
                        </a>
                        <a href={Config.pdf.home.certificate_2_pdf} download>
                            <Icon src={Config.images.home.certificate_2} />
                        </a>
                        {
                            this.props.lang === 'sr' ?
                                <>
                                    <a href={Config.pdf.home.certificate_3_pdf} download>
                                        <Icon src={Config.images.home.certificate_3} />
                                    </a>
                                    <a href={Config.pdf.home.certificate_4_pdf} download>
                                        <Icon src={Config.images.home.certificate_4} />
                                    </a>
                                    <a href={Config.pdf.home.certificate_5_pdf} download>
                                        <Icon src={Config.images.home.certificate_5} />
                                    </a>
                                </>
                                :
                                <>

                                    <a href={Config.pdf.home.certificate_3_pdf} download>
                                        <Icon src={this.props.lang === 'en' ? Config.images.home.certificate_3_eng : Config.images.home.certificate_3_deu} />
                                    </a>
                                    <a href={Config.pdf.home.certificate_4_pdf} download>
                                        <Icon src={this.props.lang === 'en' ? Config.images.home.certificate_4_eng : Config.images.home.certificate_4_deu} />
                                    </a>
                                    <a href={Config.pdf.home.certificate_5_pdf} download>
                                        <Icon src={this.props.lang === 'en' ? Config.images.home.certificate_5_eng : Config.images.home.certificate_5_deu} />
                                    </a>
                                </>
                        }
                    </InlineIcon>
                </Column>
            </Container>
        )
    }
}

export default connect(mapDispatchToProps, null)(CertificateList);