import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
margin-top: 20px;
margin-bottom: 80px;
width: 105px;
background-color: rgb(28, 120 ,187);
border: none;
padding-top: 6px;
padding-bottom: 6px;
padding-left: 12px;
padding-right: 12px;
font-size: 16px;
font-weight: bold;
font-family: 'Ubuntu';
color: #FFFFFF;
text-align: center;
transition: background-color 0.3s;
:hover{
    cursor: pointer;
    background-color: rgb(247, 148, 29);
}
`;

function Button(props) {
    return (<Container type='submit' onClick={() => props.onClick()}>{props.text}</Container>)
}

export default Button;