import styled from 'styled-components';

export const Container = styled.div`
margin-top: 10px;
display: inline-flex;
flex-wrap: nowrap;
align-self: center;
width: 1000px;
flex-direction: column;
display: -webkit-flex;
display: -moz-flex;
white-space: nowrap;
height: 400px;
overflow: hidden;
background-color: white;

@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 500px;
    height: auto;
    background-color: rgba( 226,226,226 , 1);
}
@media only screen and (max-width: 510px) {
    /* width: 400px; */
    width: 100%;
}
@media only screen and (max-width: 400px) {
    /* width: 275px; */
    width: 100%;
}
`;
export const Column = styled.div`
display: flex;
flex-direction: column;
white-space: nowrap;
vertical-align: top;
margin-bottom: 20px;
height: 100%;
@media only screen and (max-width: 850px) {
    margin-bottom: 0px;
}
`;
export const Section = styled.div` 
background-color: rgba( 226,226,226 , 1);
display: inline-block;
vertical-align: top;
width: 50%;
/* margin-right: ${ props => props.first ? '10%' : '0'}; */
white-space: nowrap;
height: 350px;
/* @media only screen and (max-width: 1250px){
    height: 400px;
} */
@media only screen and (max-width: 1250px) {
    /* height: */
}
@media only screen and (max-width: 850px) {
    height: 100%;
    width: 100%;
    margin-left: 0%;
    display: block;
    margin-bottom: 5px;
    ${ props => {
        if (props.graySpace === true) {
            return 'margin-top: 20px; margin-bottom: 45px;';
        }
    }
    }
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Hack = styled.div`height:100%;`;
export const Inline = styled.div`
display: inline;
@media only screen and (max-width: 850px) {
    display: inline-block;
}
`;

export const InlineIcon = styled.div`
margin-top: 45px;
@media only screen and (max-width: 1250px) {
    margin-top: 0px;
}
@media only screen and (max-width: 850px) {
    margin-top: 10px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;

export const Image = styled.img`
position: relative;

width: 100%;
height: 100%;
@media only screen and (max-width: 1250px) {
    min-height: 310px;
}
@media only screen and (max-width: 850px) {
    min-height: 100%;
}
`;

export const Content = styled.div`
width: 100%;
height: 150px;
background-color: yellow;
`;
export const Header = styled.div`
width: 100%;
text-align: left;
margin-bottom: 20px;
@media only screen and (max-width: 850px) {
    text-align: center;
}
`;
export const Center = styled.div`

`;
export const Title = styled.h2`
display: inline-block;
display: flex;
flex-direction: row;
color: rgb(28, 120 ,187);
font-size: 31px;
white-space: nowrap;
width: auto;
font-weight: bold;
font-family: "Ubuntu";

@media only screen and (max-width: 850px) {
  align-self: center;
 font-size: 27px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;

export const Padding = styled.div`
width: 85%;
margin-left: 7.5%;
margin-top: 5%;

@media only screen and (max-width: 1252px) {
margin-top: 1%;
}
`;
export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 55px;
max-height: 3px;
border-bottom: 4px solid rgb(247,148,29);
/* experimental  */
color: black;
@media only screen and (max-width: 850px) {

}
`;
export const WrapperWrapper = styled.div`
width:100%;
display: flex;
flex-direction: column;
`;
export const Icon = styled.img`
width: 100px;
height: 100px;
border-radius: 25px;
display: inline-block;
margin-right: 20px;
@media only screen and (max-width: 1400px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 850px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;