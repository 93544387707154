import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';

class Navigation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: 0,
            userName: ''
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        if (this.state.windowWidth > 800)
            return <Desktop />;
        else
            return <Mobile />;
    }

}

export default Navigation;