import React from 'react';
import {
    Container,
    Content,
    Title,
    Trigger,
    BlueTextBold,
    BlueTextNormal,
    ButtonsContainer,
    Buttons,
    Button,
    Wrapper,
    Fade,
    Arrow,
    WithMoreElements,
    Inline,
    ExtraElement,
    MeZezas
} from './styled';
import { connect } from 'react-redux';
import { Paragraph } from '../../styles';
import Config from '../../config';

const mapStateToProps = (state) => {
    return {
        text: state.language.about,
        animating: false
    }
};

class Reviews extends React.PureComponent {
    state = {
        selectedIndex: 0,
        selectedIndexButton: 0,
        outDirection: 'left'
    }

    componentDidUpdate(prevProps) {
        if (this.props.text !== prevProps.text) {
            this.setState({ ...this.state, selectedIndex: 0, selectedIndexButton: 0 })
        }
    }
    componentDidMount() {
        this.autoChange();
    }
    autoChange() {
        window.setInterval(() => {
            let index = (this.state.selectedIndex + 1) < this.props.text.partners_content.length ? (this.state.selectedIndex + 1) : 0;
            this.handleClick(index, 'right');
        }, 15000);
    }
    changeToDirection(direction) {
        let index = 0;
        if (direction === 'right')
            index = (this.state.selectedIndex + 1) < this.props.text.partners_content.length ? (this.state.selectedIndex + 1) : 0;
        if (direction === 'left')
            index = (this.state.selectedIndex - 1) > -1 ? (this.state.selectedIndex - 1) : (this.props.text.partners_content.length - 1);
        this.handleClick(index, direction);
    }

    handleClick(index, direction) {
        this.setState({ ...this.state, selectedIndexButton: index, animating: true, outDirection: direction });
        window.setTimeout(() => { this.setState({ ...this.state, selectedIndex: index, animating: false, outDirection: direction }) }, 500)
    }

    render() {
        return (
            <Trigger>
                <WithMoreElements>
                    <span>
                        <ExtraElement left={true} onClick={() => this.changeToDirection('left')}>
                            <Arrow src={Config.images.general.ArrowRight} left={true} />
                        </ExtraElement>
                        <Container>
                            {this.props.text ?
                                <>
                                    <Title>{this.props.text.partners_title}<Wrapper></Wrapper></Title>
                                    <Content>
                                        <Fade out={this.state.animating} outDirection={this.state.outDirection}>
                                            <Paragraph>{this.props.text.partners_content[this.state.selectedIndex < this.props.text.partners_content.length ? this.state.selectedIndex : 0].text}</Paragraph>
                                            <br />
                                            <BlueTextBold>{this.props.text.partners_content[this.state.selectedIndex < this.props.text.partners_content.length ? this.state.selectedIndex : 0].name}</BlueTextBold>
                                            <BlueTextNormal>{this.props.text.partners_content[this.state.selectedIndex < this.props.text.partners_content.length ? this.state.selectedIndex : 0].title}</BlueTextNormal>
                                        </Fade>
                                        <ButtonsContainer>
                                            <Buttons>
                                                {
                                                    this.props.text.partners_content.map((element, index) => {
                                                        return (<Button
                                                            key={index}
                                                            active={this.state.selectedIndexButton === index ? true : false}
                                                            onClick={() => this.handleClick(index)}
                                                        />);
                                                    })
                                                }
                                            </Buttons>
                                        </ButtonsContainer>
                                    </Content>
                                </>
                                :
                                <></>
                            }
                        </Container>
                        <ExtraElement right={true} onClick={() => this.changeToDirection('right')}>
                            <Arrow src={Config.images.general.ArrowRight} right={true} />
                        </ExtraElement>
                    </span>
                </WithMoreElements>
            </Trigger>
        )
    }
}

export default connect(mapStateToProps, null)(Reviews);