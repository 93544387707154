import React from 'react';

import {
    Container,
    ImageContainer,
    Inline,
    ImageView,
    MagnifierImage,
    FuckingImage,
    Cmon,
    Cmon2
} from './styled';

import Config from '../../config';
import LargeImageShow from '../LargeImageShow';

class GalleryContent extends React.Component {
    state = {
        hoverId: -1,
        selectedImage: '',
        showFull: false,
        category: 'all',
        windowWidth: 0,
        selectedIndex: 0,
    }
    ////////////////////////////////////////
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }
    ///////////////////////////////////////////
    handleHover(id, leave) {
        this.setState({ ...this.state, hoverId: leave === true ? -1 : id });
    }
    handleClick(image, index) {
        this.setState({ ...this.state, showFull: true, selectedImage: image, selectedIndex: index });
    }
    handleFullChange(index, direction) {
        let next = index;
        if (direction === 'right') {
            next = (next + 1) > Config.images.gallery.length - 1 ? 0 : next + 1;
        }
        if (direction === 'left') {
            next = (next - 1) < 0 ? Config.images.gallery.length - 1 : next - 1;
        }
        if (this.props.category === 'mercedes') {
            // alert('yes')

            let searching = true;
            if (direction === 'right') {
                next = index + 1;
                while (searching) {
                    if (next === Config.images.gallery.length) {
                        next = 0;
                    }
                    // if (Config.images.gallery[next].type === 'mercedes') {
                    if (Config.images.gallery[next].type === 'mercedes' || Config.images.gallery[next].type === 'both') {

                        searching = false;
                    }
                    else {
                        next += 1;
                    }
                }
            }
            if (direction === 'left') {
                next = index - 1;
                while (searching) {
                    if (next < 0) {
                        next = Config.images.gallery.length - 1;
                    }
                    if (Config.images.gallery[next].type === 'mercedes' || Config.images.gallery[next].type === 'both') {
                        // if (Config.images.gallery[next].type === 'mercedes') {

                        searching = false;
                    }
                    else {
                        next -= 1;
                    }
                }
            }
        }
        if (this.props.category === 'iveco') {
            // alert('yes')

            let searching = true;
            if (direction === 'right') {
                next = index + 1;
                while (searching) {
                    if (next === Config.images.gallery.length) {
                        next = 0;
                    }
                    if (Config.images.gallery[next].type === 'iveco' || Config.images.gallery[next].type === 'both') {
                        // if (Config.images.gallery[next].type === 'iveco') {

                        searching = false;
                    }
                    else {
                        next += 1;
                    }
                }
            }
            if (direction === 'left') {
                next = index - 1;
                while (searching) {
                    if (next < 0) {
                        next = Config.images.gallery.length - 1;
                    }
                    // if (Config.images.gallery[next].type === 'iveco') {
                    if (Config.images.gallery[next].type === 'iveco' || Config.images.gallery[next].type === 'both') {

                        searching = false;
                    }
                    else {
                        next -= 1;
                    }
                }
            }
        }
        this.setState({ ...this.state, selectedImage: Config.images.gallery[next].small, selectedIndex: next });
    }
    handleFullClose() {
        this.setState({ ...this.state, showFull: false, selectedImage: '', hoverId: -1 })
    }

    render() {
        return (
            <Container>
                <Inline>
                    {
                        Config.images.gallery.map((element, index) => {
                            if (this.props.category === 'all') {
                                return (
                                    <ImageContainer
                                        image={element.small}
                                        onMouseEnter={() => this.handleHover(element.id, false)}
                                        onMouseLeave={() => this.handleHover(element.id, true)}
                                    >
                                        <ImageView show={element.id === this.state.hoverId}>
                                            {element.id === this.state.hoverId ?
                                                <Cmon>
                                                    {
                                                        this.state.windowWidth > 900 ?
                                                            <MagnifierImage src={Config.images.general.Magnifier} onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                            />
                                                            :
                                                            <></>
                                                    }
                                                    <Cmon2 onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                    ></Cmon2>
                                                    <FuckingImage src={element.small} No={true} />
                                                </Cmon>
                                                :
                                                <>
                                                    <FuckingImage src={element.small} />
                                                </>
                                            }
                                        </ImageView>
                                    </ImageContainer>
                                )
                            }
                            if (this.props.category === 'iveco') {
                                // if (element.type === 'iveco') {
                                if (element.type === 'iveco' || element.type === 'both') {

                                    return (
                                        <ImageContainer
                                            image={element.small}
                                            onMouseEnter={() => this.handleHover(element.id, false)}
                                            onMouseLeave={() => this.handleHover(element.id, true)}
                                        >
                                            <ImageView show={element.id === this.state.hoverId}>
                                                {element.id === this.state.hoverId ?
                                                    <Cmon>
                                                        {
                                                            this.state.windowWidth > 900 ?
                                                                <MagnifierImage src={Config.images.general.Magnifier} onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                        <Cmon2 onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                        ></Cmon2>
                                                        <FuckingImage src={element.small} No={true} />
                                                    </Cmon>
                                                    :
                                                    <>
                                                        <FuckingImage src={element.small} />
                                                    </>
                                                }
                                            </ImageView>
                                        </ImageContainer>
                                    )
                                }
                            }
                            if (this.props.category === 'mercedes') {
                                if (element.type === 'mercedes' || element.type === 'both') {
                                    // if (element.type === 'mercedes') {
                                    return (
                                        <ImageContainer
                                            image={element.small}
                                            onMouseEnter={() => this.handleHover(element.id, false)}
                                            onMouseLeave={() => this.handleHover(element.id, true)}
                                        >
                                            <ImageView show={element.id === this.state.hoverId}>
                                                {element.id === this.state.hoverId ?
                                                    <Cmon>
                                                        {
                                                            this.state.windowWidth > 900 ?
                                                                <MagnifierImage src={Config.images.general.Magnifier} onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                        <Cmon2 onClick={() => this.handleClick(this.state.windowWidth > 1350 ? element.large : element.small, index)}
                                                        ></Cmon2>
                                                        <FuckingImage src={element.small} No={true} />
                                                    </Cmon>
                                                    :
                                                    <>
                                                        <FuckingImage src={element.small} />
                                                    </>
                                                }
                                            </ImageView>
                                        </ImageContainer>
                                    )
                                }
                            }
                        })

                    }
                    <LargeImageShow
                        mobile={this.state.windowWidth > 1350 ? false : true}
                        show={this.state.showFull}
                        src={this.state.selectedImage}
                        close={() => this.handleFullClose()}
                        handleChange={(index, direction) => this.handleFullChange(index, direction)}
                        index={this.state.selectedIndex}
                    />
                </Inline>
            </Container>
        );
    }
}

export default GalleryContent;