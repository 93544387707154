import styled, { keyframes } from 'styled-components';


export const Container = styled.div`
display: inline-block;
z-index: -1;
/* background-color: orange; */
float: center;
width: 640px;
text-align: center;
margin-top: 30px;
align-self: center;
margin-bottom: 80px;
@media only screen and (max-width: 850px) {
    width: 400px;
    margin-top: 65px;
    /* text-align: left; */
}
@media only screen and (max-width: 510px) {
    width: 350px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;
const fadeIn = keyframes`
  /*from {
    transform: scale(.9);
    transform: translateX(-50%); 
    opacity: 0;
  } 

  to {
    transform: scale(1);
    transform: translateX(0); 
    opacity: 1;
  } */
  from { 
   transform: translateX(-100%);
  }
  to{
    transform: translateX( 0%);
  }
`;

const fadeOut = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( 100%);
  }
`;

const fadeIn2 = keyframes`
  /*from {
    transform: scale(.9);
    transform: translateX(-50%); 
    opacity: 0;
  } 

  to {
    transform: scale(1);
    transform: translateX(0); 
    opacity: 1;
  } */
  from { 
   transform: translateX( 100%);
  }
  to{
    transform: translateX( 0%);
  }
`;

const fadeOut2 = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( -100%);
  }
`;

export const Fade = styled.div`
  display: inline-block;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => {
    if (props.outDirection === 'left') {
      if (props.out === true) { return fadeOut; } else { return fadeIn; }
    }
    if (props.outDirection === 'right') {
      if (props.out === true) { return fadeOut2; } else { return fadeIn2; }
    }

  }}
      0.5s linear;
  transition: visibility 0.5s linear;
`;

export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 55px;
max-height: 3px;
margin-top: 8px;
border-bottom: 4px solid rgb(247,148,29);
/* experimental  */
color: black;

@media only screen and (max-width: 850px) {

}
`;
export const WrapperWrapper = styled.div`
width:100%;
display: flex;
flex-direction: column;
`;

export const Title = styled.h2`
color: rgb(28, 120 ,187);
font-size: 31px;
white-space: nowrap;
font-family: "Ubuntu";
display: inline-block;
width: auto;
font-weight: bold;
@media only screen and (max-width: 850px) {
 font-size: 27px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;

export const Border = styled.div`
width: 100px;
border-bottom: 5px solid rgb(246 , 149 ,32);
`;
export const Content = styled.div`
width: 100%;
text-align: left;
overflow: hidden;
`;

export const Trigger = styled.div`
z-index: 2;
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
overflow: hidden;
/* background-color: rgba( 255 , 255, 255 , 1); */
`;
export const WithMoreElements = styled.div`
display: flex;
flex-direction: column;
align-self: center;
/* background-color: purple; */
vertical-align: center;
`;
export const MeZezas = styled.div`
height: 200px;
`;
export const ExtraElement = styled.div`
width: 10px;
position: relative;
display: inline-block;
height: 200px;
top: -37px;
${ props => {
    if (props.left === true) {
      return 'margin-right: 40px;';
    }
    if (props.right === true) {
      return 'margin-left: 40px;';
    }
  }};
@media only screen and (max-width: 550px) {
  ${ props => {
    if (props.left === true) {
      return 'margin-right: 20px; top: -50px;';
    }
    if (props.right === true) {
      return 'margin-left: 20px; top: -50px;';
    }
  }};
  
}
  @media only screen and (max-width: 510px) {
  ${ props => {
    if (props.left === true) {
      return 'margin-right: 20px; top: -70px;';
    }
    if (props.right === true) {
      return 'margin-left: 20px; top: -70px;';
    }
  }}
  }
  @media only screen and (max-width: 419px) {
  ${ props => {
    if (props.left === true) {
      return 'margin-right: 10px; ';
    }
    if (props.right === true) {
      return 'margin-left: 10px; ';
    }
  }}
  }
  @media only screen and (max-width: 400px) {
  ${ props => {
    if (props.left === true) {
      return 'margin-right: 20px; ';
    }
    if (props.right === true) {
      return 'margin-left: 20px; ';
    }
  }}
  }
  @media only screen and (max-width: 350px) {
  ${ props => {
    if (props.left === true) {
      return 'margin-right: 10px; ';
    }
    if (props.right === true) {
      return 'margin-left: 10px; ';
    }
  }}
  }
`;

export const Arrow = styled.img`
position: absolute;
display: inline-block;
width: 14px;
height: 20px;
padding-top: 0px;
margin-top: 0px;
:hover{
  cursor: pointer;
}
${ props => {
    if (props.left === true) {
      return 'transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg);  -webkit-transform: rotate(180deg);  -o-transform: rotate(180deg); ';
    }
    if (props.right === true) {
    }
  }}
`;
export const Inline = styled.div`
display: inline;
`;
export const BlueTextNormal = styled.p`
margin-top: 0;
font-size: 17px;
color: rgb(28, 120 ,187);
font-weight: normal;
white-space: pre-line;
`;
export const BlueTextBold = styled.p`
font-size: 16px;
margin-bottom:  3px;
color: rgb(28, 120 ,187);
font-weight: bold;
`;

export const ButtonsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
overflow: hidden;
margin-top: 50px;
`;
export const Buttons = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
overflow: hidden;
align-self: center;
height: 20px;
width: 75px;
`;

export const Button = styled.div`
display: flex;
flex-direction: row;
align-self: center;
border-radius: 20px;
width: 14px;
height: 14px;
border: 2px solid orange;
transition: background-color 0.5s;
background-color: ${ props => { if (props.active === true) { return 'orange'; } else { return ''; } }};
:hover{
    cursor: pointer;
}
`;