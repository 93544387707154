import React from 'react';
import {
    Container,
    Label,
    Content,
    Inline,
    Element,
    Logo,
    DropDown,
    ListItem,
    SubContainer,
    MobileMenu,
    SideDrawer,
    MenuItem,
    LinkItem,
    Arrow
} from './styled';
import MobileMenuImage from '../../../assets/icon/MobileMenu.png';

import LogoImage from '../../../assets/logo/logo.png';

import { connect } from 'react-redux';
import Actions from '../../../store/Actions';
import Config from '../../../config';

import axios from 'axios';

const mapStateToProps = (state) => {
    return {
        lang: state.language
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        languageSetSerbian: () => dispatch(Actions.changeLanguage.serbian()),
        languageSetEnglish: () => dispatch(Actions.changeLanguage.english()),
        languageSetGerman: () => dispatch(Actions.changeLanguage.german()),
        startScroll: () => dispatch(Actions.scroll.startScroll()),
    }
}

class Mobile extends React.Component {
    state = {
        loading: true,
        dropdown: false,
        menu: false
    }
    handleLanguage(lang) {
        if (lang === 'en') {
            this.props.languageSetEnglish();
        }
        if (lang === 'sr') {
            this.props.languageSetSerbian();
        }
        if (lang === 'de') {
            this.props.languageSetGerman();
        }
        this.setState({ ...this.state, dropdown: false });
    }
    showLanguage() {
        this.setState({ ...this.state, dropdown: !this.state.dropdown });
    }
    showMenu() {
        this.setState({ ...this.state, menu: !this.state.menu });
    }
    componentDidMount() {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    switch (data.country_name) {

                        case 'United Kingdom':
                            this.props.languageSetEnglish();
                            break;
                        case 'Serbia':
                            this.props.languageSetSerbian();
                            break;
                        case 'Germany':
                            this.props.languageSetGerman();
                            break;
                        case 'Austria':
                            this.props.languageSetGerman();
                            break;
                        default:
                            this.props.languageSetEnglish();

                    }

                }).catch((error) => {
                    console.log(error);
                });
                this.setState({ ...this.state, loading: false });
            }
            else {
                this.setState({ ...this.state, loading: false });
            }
        }
        catch (err) {
            this.props.languageSetEnglish();
            this.setState({ ...this.state, loading: false });
        }
    }
    render() {
        return (
            <>
                {
                    this.props.lang && !this.state.loading ?
                        <div >
                            <Container>
                                <SubContainer>
                                    <Inline>
                                        <Logo src={LogoImage} />
                                        <Content>
                                            <Inline>
                                                <Element onClick={() => this.showLanguage()}> <Label>{this.props.lang.navigation.languageShort}<Arrow src={Config.images.general.DownArrowMenu} /></Label></Element>
                                            </Inline>
                                        </Content>
                                        <MobileMenu src={MobileMenuImage} onClick={() => this.showMenu()} />
                                    </Inline>
                                </SubContainer>
                            </Container>
                            <SubContainer>
                                <DropDown show={this.state.dropdown}>
                                    <ListItem style={{ marginTop: '30px' }} onClick={() => this.handleLanguage('en')}>{this.props.lang.navigation.languages.english}</ListItem>
                                    <ListItem onClick={() => this.handleLanguage('de')}>{this.props.lang.navigation.languages.german}</ListItem>
                                    <ListItem onClick={() => this.handleLanguage('sr')}>{this.props.lang.navigation.languages.serbian}</ListItem>
                                </DropDown>
                                <SideDrawer open={this.state.menu}>
                                    <MenuItem ><LinkItem to='/' onClick={() => this.showMenu()}>{this.props.lang.navigation.home}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/about' onClick={() => { this.showMenu(); this.props.startScroll() }}>{this.props.lang.navigation.about}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/gallery' onClick={() => { this.showMenu(); this.props.startScroll() }}>{this.props.lang.navigation.certificate}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/history' onClick={() => this.showMenu()}>{this.props.lang.navigation.history}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/contact' onClick={() => this.showMenu()}>{this.props.lang.navigation.contact}</LinkItem></MenuItem>
                                </SideDrawer>
                            </SubContainer>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);