import React from 'react';
import Main from './Main';
import { AppContainer } from './styles';
import store from './store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <AppContainer>
        <Main />
      </AppContainer>
    </Provider>
  );
}

export default App;