import React from 'react';
import MainSection from '../MainSection';
import { InputField, Border, TextArea } from './styled';
import { connect } from 'react-redux';
import Button from '../Button';
import { Paragraph, RedText } from '../../styles';
import Config from '../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import EmailValidator from 'email-validator';
import { detect } from 'detect-browser';


const mapStateToProps = (state) => {
    return {
        text: state.language.contact
    }
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.submitCaptcha = this.submitCaptcha.bind(this);
        this.emailRef = React.createRef();
        this.formRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.validation = this.validation.bind(this);
    }

    state = {
        recaptcha: false,
        validated: false,
        email: '',
        warningName: 'Username sucks',
        warningEmail: 'Invalid email address',
        warningText: 'Text should containt minimum 50 characters',
        validEmail: false,
        validName: false,
        validText: false,
        browser: ''
    }
    submitCaptcha(response) {
        if (response) {
            this.setState({ ...this.state, recaptcha: true });
        }
    }
    onExpiredCaptcha() {
        this.setState({ ...this.state, recaptcha: false });
    }
    notValidated() {

    }
    validation(event) {
        event.preventDefault();
        let exists = EmailValidator.validate(this.state.email);
        if (exists === true) {
            this.setState({ ...this.state, validated: true });
            this.submitForm(event);
        }
        else {
            this.setState({ ...this.state, status: 'Invalid email address' })
        }

    }

    onChange(event) {
        let text = event.target.value
        let id = event.target.id;
        let adjustments = {};

        if (id === 'Email') {
            let valid = EmailValidator.validate(text);
            if (valid === true) {
                adjustments = { ...adjustments, validEmail: true };
            }
            else {
                adjustments = { ...adjustments, validEmail: false };
            }
        }
        if (id === 'Name') {
            if (text.length > 2) {
                adjustments = { ...adjustments, validName: true };
            }
            else {
                adjustments = { ...adjustments, validName: false };
            }
        }
        if (id === 'Text') {
            if (text.length > 50) {
                adjustments = { ...adjustments, validText: true };
            }
            else {
                adjustments = { ...adjustments, validText: false };
            }
        }

        this.setState({ ...this.state, ...adjustments });

    }

    render() {
        return (
            <>
                {
                    this.props.text ?
                        <>

                            <MainSection title={this.props.text.title} noBottom={true}>
                                <form
                                    // onSubmit={this.onSubmit}
                                    // action={Config.formSpree}
                                    id="contactform"
                                    action="https://formsubmit.io/send/28c463e9-d727-4db0-a649-65fc55d78d58"
                                    method="POST"
                                >
                                    <input name="_redirect" type="hidden" id="name" value="https://meggapozega.com/#/contact_submit" ></input>
                                    <InputField type='text' name='name' oninvalid='invalid' placeholder={this.props.text.name} required onChange={this.onChange} id="Name" />
                                    {/* <Paragraph>Invalid email address</Paragraph> */}
                                    <InputField type='text' name='email' oninvalid='invalid 2' placeholder={this.props.text.email} required onChange={this.onChange} id="Email" /> {/*value={this.state.email} onChange={this.onChange}*/}
                                    {/* <Paragraph>Invalid email address</Paragraph> */}
                                    <TextArea type='text' name='message' oninvalid='invalid 3' placeholder={this.props.text.message} required onChange={this.onChange} id="Text" />
                                    <Paragraph style={{ marginTop: '5px' }}>{this.state.status ? this.state.status : ''}</Paragraph>
                                    <Paragraph><RedText>{!this.state.validName ? this.props.text.nameError : ''} </RedText></Paragraph>
                                    <Paragraph><RedText>{!this.state.validEmail ? this.props.text.emailError : ''}</RedText></Paragraph>
                                    <Paragraph><RedText>{!this.state.validText ? this.props.text.textError : ''}</RedText></Paragraph>
                                    {
                                        this.state.error !== 1 && this.state.recaptcha && this.state.validName && this.state.validEmail && this.state.validText ?
                                            <Button type='submit' text={this.props.text.send} onClick={() => { console.log('') }} />
                                            :
                                            <></>
                                    }
                                </form>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '60px' }}>
                                    <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <ReCAPTCHA
                                            render="explicit"
                                            sitekey="6Lc0iLUZAAAAAGIm6KO4x8M4XC6l4TxRKQZ28BK8"
                                            onVerifyCallBack={this.submitCaptcha}
                                            onChange={this.submitCaptcha}
                                            onExpired={this.onExpiredCaptcha}
                                        />
                                    </div>
                                </div>
                            </MainSection>
                        </>
                        :
                        <></>
                }
            </>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ error: 1, status: this.props.text.success, email: '' });
            } else {
                this.setState({ error: 1, status: this.props.text.failed, email: '' });
            }
        };
        xhr.send(data);

    }
};

export default connect(mapStateToProps, null)(ContactForm);