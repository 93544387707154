import React from 'react';
import { PageContainer, Fade } from '../../styles';
import GalleryContent from '../../components/GalleryContent';
import GalleryFilter from '../../components/GalleryFilter';
import PrivacyPolic from '../../components/PrivacyPolicy';
class Gallery extends React.Component {

    state = {
        category: 'all',
        animating: false,
        windowWidth: 0
    }
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.top = React.createRef();
    }
    handleCategoryChange(category) {
        this.setState({ ...this.state, animating: true });
        window.setTimeout(() => { this.setState({ ...this.state, category, animating: false }) }, 500)
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        if (this.top.current) {
            this.top.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        return (
            <div>
                <div ref={this.top}></div>
                <GalleryFilter onClick={(category) => this.handleCategoryChange(category)} selected={this.state.category} />
                <PageContainer>
                    {
                        this.state.windowWidth > 850 ?
                            <Fade outDirection={'right'} out={this.state.animating}>
                                <GalleryContent category={this.state.category}></GalleryContent>
                            </Fade>
                            :
                            <>
                                <GalleryContent category={this.state.category}></GalleryContent>
                            </>
                    }
                    <PrivacyPolic />
                </PageContainer>
            </div>
        )
    }
}

export default Gallery;