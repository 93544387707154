import styled from 'styled-components';


export const Background = styled.div`
display: ${ props => { if (props.show === true) { return 'none'; } else { return 'none'; } }};
position: fixed;
width: 100%;
top: 0;
height: 100%;
z-index: 9;
background-color: rgba(111, 112, 112 , 0.7);
display: flex;
flex-direction: column;
overflow: scroll;
`;
export const MessageContainer = styled.div`
padding: 10px 10px 10px 10px;
display: flex;
flex-direction: column;
width: 100%;
border: 1px solid gray;
height: 300px;
overflow-y: scroll;
border-radius: 3px;
@media only screen and (max-width: 1400px) {
    height: 250px;
}
@media only screen and (max-width: 1200px) {
    height: 250px;
}
@media only screen and (max-width: 850px) {
    height: 200px;
}
`;
export const Container = styled.div`
border-radius: 7px;
margin-top: 200px;

background-color: white;
display: flex;
align-self: center;
flex-wrap: wrap;
overflow: hidden;

width: 450px;

@media only screen and (max-width: 1000px) {
    margin-top: 100px;
}
@media only screen and (max-width: 500px) {
    margin-top: 50px;
    width: 100%;
}
`;
export const Buttons = styled.div`
width: 80%;
margin-left:15%;
@media only screen and (max-width: 450px) {
    width: 100%;
    margin-left: 0;
}
`;
export const Inline = styled.div`
display: inline;
flex-direction: column;
flex-wrap: nowrap;
overflow: hidden;
text-align: center;
justify-content: center;
align-content: center;
`;
export const ButtonLeft = styled.p`
display: inline-block;
flex-direction: row;
align-self: flex-start;
background-color: rgb(28, 120 ,187);
float: center;
/* left: 200px; */
padding-top: 9px;
padding-bottom: 9px;
padding-left: 15px;
padding-right: 15px;
font-size: 16px;
font-weight: bold;
font-family: 'Ubuntu';
color: #FFFFFF;
text-align: center;
transition: background-color 0.3s;
@media only screen and (max-width: 450px) {
    font-size: 13px;
}
:hover{
    cursor: pointer;
    background-color: rgb(247, 148, 29);
}
`;

export const ButtonRight = styled.p`
display: inline-block;
float: center;
background-color: rgb(28, 120 ,187);
padding-top: 9px;
padding-bottom: 9px;
padding-left: 15px;
padding-right: 15px;
font-size: 16px;
font-weight: bold;
font-family: 'Ubuntu';
color: #FFFFFF;
text-align: center;
transition: background-color 0.3s;
@media only screen and (max-width: 450px) {
    font-size: 13px;
}
:hover{
    cursor: pointer;
    background-color: rgb(247, 148, 29);
}
`;