import React from 'react';
import { Container, Section, Image, Column, ForReal, TextContent } from './styled';
import { Inline, Paragraph } from '../../styles';
import Image1 from '../../assets/pictures/history/image.png';
import Image2 from '../../assets/pictures/history/image2.png';
import Image3 from '../../assets/pictures/history/image3.png';
import Image4 from '../../assets/pictures/history/image4.png';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade'
const mapStateToProps = (state) => {
    return {
        text: state.language.history
    }
}

class HistoryContent extends React.Component {
    render() {
        return (

            <Container>
                {
                    this.props.text ?
                        <>
                            <Column>
                                <Inline>
                                    <Section first>
                                        <Fade left>
                                            <Image src={Image1} />
                                            <ForReal>
                                                <TextContent>
                                                    <Paragraph>{this.props.text.text1}</Paragraph>
                                                </TextContent>
                                            </ForReal>
                                        </Fade>
                                    </Section>
                                    <Section>
                                        <Fade right>
                                            <Image src={Image2} />
                                            <ForReal>
                                                <TextContent>
                                                    <Paragraph>{this.props.text.text2}</Paragraph>
                                                </TextContent>
                                            </ForReal>
                                        </Fade>
                                    </Section>
                                </Inline>
                            </Column>
                            <Column>
                                <Inline>
                                    <Section first>
                                        <Fade left>
                                            <Image src={Image3} />
                                            <ForReal>
                                                <TextContent>
                                                    <Paragraph>{this.props.text.text3}</Paragraph>
                                                </TextContent>
                                            </ForReal>
                                        </Fade>
                                    </Section>
                                    <Section>
                                        <Fade right>
                                            <Image src={Image4} />
                                            <ForReal>
                                                <TextContent>
                                                    <Paragraph>{this.props.text.text4}</Paragraph>
                                                </TextContent>
                                            </ForReal>
                                        </Fade>
                                    </Section>
                                </Inline>
                            </Column>
                        </>
                        :
                        <>
                        </>
                }
            </Container>

        );
    }
}

export default connect(mapStateToProps, null)(HistoryContent);