import React from 'react';
import { Container, Element, FacebookIcon, Break, TextElement, Text, Inline, Section, MarginTop } from './styled';
import Config from '../../config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        text: state.language.gdpr
    }
};

class PrivacyPolicy extends React.Component {
    goToLink() {
        window.open(
            "https://www.facebook.com/MeggaPozega/",
            '_blank' // <- ;)
        );
    }
    render() {
        return (
            <MarginTop>
                <Element>
                    <Container>
                        <FacebookIcon src={Config.images.home.facebook} onClick={() => this.goToLink()} />
                    </Container>
                </Element>
                <TextElement>
                    <Container>
                        <Section>
                            <Inline>
                                {
                                    this.props.text ?
                                        <>
                                            <Link to='/cookies'>
                                                <Text>{this.props.text.cookies}</Text>
                                            </Link>
                                            <Link to='/privacy'>
                                                <Text align={'right'}>{this.props.text.privacy}</Text>
                                            </Link>
                                        </>
                                        :
                                        <></>
                                }
                            </Inline>
                        </Section>
                    </Container>
                </TextElement>
            </MarginTop>
        )
    }
}

export default connect(mapStateToProps, null)(PrivacyPolicy);