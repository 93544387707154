import React from 'react';
import {
    Container,
    Label,
    Content,
    Inline,
    Element,
    Logo,
    DropDown,
    ListItem,
    SubContainer,
    Arrow
} from './styled';

import LogoImage from '../../../assets/logo/logo.png';

import { connect } from 'react-redux';
import Actions from '../../../store/Actions';

import axios from 'axios';
import Config from '../../../config';

const mapStateToProps = (state) => {
    return {
        lang: state.language
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        languageSetSerbian: () => dispatch(Actions.changeLanguage.serbian()),
        languageSetEnglish: () => dispatch(Actions.changeLanguage.english()),
        languageSetGerman: () => dispatch(Actions.changeLanguage.german()),
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
    }
}

class Desktop extends React.Component {
    state = {
        loading: true,
        dropdown: false,
    }
    handleLanguage(lang) {
        if (lang === 'en') {
            this.props.languageSetEnglish();
        }
        if (lang === 'sr') {
            this.props.languageSetSerbian();
        }
        if (lang === 'de') {
            this.props.languageSetGerman();
        }
        this.setState({ ...this.state, dropdown: false });
    }
    showLanguage() {
        this.setState({ ...this.state, dropdown: !this.state.dropdown });
    }
    componentDidMount() {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    switch (data.country_name) {

                        case 'United Kingdom':
                            this.props.languageSetEnglish();
                            break;
                        case 'Serbia':
                            this.props.languageSetSerbian();
                            break;
                        case 'Germany':
                            this.props.languageSetGerman();
                            break;
                        case 'Austria':
                            this.props.languageSetGerman();
                            break;
                        default:
                            this.props.languageSetEnglish();

                    }

                }).catch((error) => {
                    console.log(error);
                });
                this.setState({ ...this.state, loading: false });
            }
            else {
                this.setState({ ...this.state, loading: false });
            }
        }
        catch (err) {
            this.props.languageSetEnglish();
            this.setState({ ...this.state, loading: false });
        }
    }
    render() {
        return (
            <>
                {
                    this.props.lang && !this.state.loading ?
                        <div>
                            <Container>
                                <Inline>
                                    <Logo src={LogoImage} />
                                    <Content>
                                        <Inline>
                                            <Element to='/'><Label>{this.props.lang.navigation.home}</Label></Element>
                                            <Element to='/about' onClick={() => this.props.startScroll()}><Label>{this.props.lang.navigation.about}</Label></Element>
                                            <Element to='/gallery' onClick={() => this.props.startScroll()}><Label>{this.props.lang.navigation.certificate}</Label></Element>
                                            <Element to='/history' onClick={() => { }}><Label>{this.props.lang.navigation.history}</Label></Element>
                                            <Element to='/contact'><Label>{this.props.lang.navigation.contact}</Label></Element>
                                            <Element onClick={() => this.showLanguage()}><Label>{this.props.lang.navigation.language}<Arrow src={Config.images.general.DownArrowMenu} /> </Label></Element>
                                            {/*▼ */}
                                        </Inline>
                                    </Content>
                                </Inline>
                            </Container>
                            <SubContainer>
                                <DropDown show={this.state.dropdown}>
                                    <ListItem onClick={() => this.handleLanguage('en')}>{this.props.lang.navigation.languages.english}</ListItem>
                                    <ListItem onClick={() => this.handleLanguage('de')}>{this.props.lang.navigation.languages.german}</ListItem>
                                    <ListItem onClick={() => this.handleLanguage('sr')}>{this.props.lang.navigation.languages.serbian}</ListItem>
                                </DropDown>
                            </SubContainer>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);