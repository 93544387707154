import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LargeHeight = '85px';

export const Container = styled.div`
z-index:5;
position: fixed;
width: 100%;
background-color: rgb( 29, 117, 189 );
border-top: 7px solid rgb( 247, 148 ,29);
height: ${LargeHeight};
white-space: nowrap;
/* position: fixed; */
/* z-index: 4; */
@media only screen and (max-width: 950px) {
    height: 45px;
}
`;

export const Logo = styled.img`
display: inline-block;
width: auto;
height: auto;
max-width: 220px;
margin-top: 12px;
@media only screen and (max-width: 950px) {
    margin-left: 5%;
}
@media only screen and (max-width: 500px) {
    max-width: 175px;
    margin-top: 15px;
}
@media only screen and (max-width: 375px) {
    max-width: 145px;
    margin-top: 18px;
}
`;

export const Inline = styled.div`
display: inline;
white-space: nowrap;
`;

export const Content = styled.div`
height: 100%;
margin-right: 15%;
float: right;
display: block;
width: auto;
white-space: nowrap;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}

`;
export const Element = styled(Link)`
text-decoration: none;
height: 100%;
width: auto;
position: absolute;
right: 55px;
top: 1px;
margin-left: 100px;
display: inline-block;
white-space: nowrap;

`;
export const Label = styled.p`
white-space: nowrap;
font-weight: bold;
font-family: "Ubuntu";
color: #FFFFFF;
margin-top: 13px;
font-size: 15px;

:hover{
    cursor: pointer;
}
`;

export const Arrow = styled.img`
width: 15px;
height: 10px;
margin-left: 5px;
`;

export const MobileMenu = styled.img`
width: 20px;
height: 20px;
position: absolute;
right: 15px;
top: 12px;
`;

export const DropDown = styled.div`
display: ${ props => { if (props.show === true) { return 'auto'; } else { return 'none'; } }};
position: fixed;
width: 120px;
    height: 150px;
right: 0;

/* background-color: rgba(86, 95, 110,0.5); */
background-color: rgb( 29, 117, 189 );
border-top: 0px solid gray;
border: 1px solid rgba(86, 95, 110 , 0.3);
text-align: left;
top: 52px;
z-index: 3;
`;

export const ListItem = styled.p`
font-size: 17px;
color: #FFFFFF;
padding-left: 20px;
margin-bottom: 5px;
font-weight: bold;
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 14px;
}
:hover{
    cursor: pointer;
}
`;
export const LinkItem = styled(Link)`
text-decoration: none;
color: #FFFFFF;
`;

export const MenuItem = styled.p`
text-decoration: none;
font-size: 17px;
color: #FFFFFF;
padding-left: 20px;
margin-bottom: 5px;
font-weight: bold;
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 14px;
}
:hover{
    cursor: pointer;
}
`;
export const SubContainer = styled.div`
position: relative;
`;

export const SideDrawer = styled.div`
position: fixed;
width: 150px;
height: 700px;
z-index: 4;
right: 0;
top: 52px;
transform: ${ props => { if (props.open === true) { return 'translateX(0)'; } else { return 'translateX(100%)'; } }};
transition: transform 0.2s ease-in;
/* background-color: rgba(86, 95, 110,0.6); */
background-color: rgb( 29, 117, 189 );
border-top: 0px solid gray;
border: 1px solid rgba(86, 95, 110 , 0.3);
text-align: left;

`;