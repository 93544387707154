import React from 'react';

import { PageContainer, Paragraph } from '../../styles';
import Config from '../../config';
import Jumbotron from '../../components/Jumbotron';
import MainSection from '../../components/MainSection';
import ContactForm from '../../components/ContactForm';
import GoogleApiWrapper from '../../components/GoogleApi';
import CookieModal from '../../components/CookiesModal';

import OrangeBox from '../../components/OrangeBox';
import PopupWindow from '../../components/PopupWindow';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import ScrollToTop from '../../components/ScrollToTop';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { detect } from 'detect-browser';


const mapStateToProps = (state) => {
    return {
        text: state.language,
        cookies: state.firstTime
    }
}

const FullWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

class Contact extends React.Component {
    state = {
        window: false,
        browser: ''
    }

    constructor(props) {
        super(props);
        this.jumbotron = React.createRef();
    }

    scrollToTop() {
        if (this.jumbotron.current) {
            this.jumbotron.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end"
                }
            )
        }
    }
    componentDidMount() {

        const browser = detect();
        let windowTrue = false;
        let browserName = '';
        if (browser) {
            if (browser.name === 'safari ') {
                browser = 'safari';
            }
            else {
            }
        }

        let setUrl = '/';
        let url = window.location.toString();
        if (url.includes('contact_submit')) {
            windowTrue = true;
        }

        if (this.jumbotron.current) {
            this.jumbotron.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
        this.setState({ ...this.state, browser: browserName, window: windowTrue })

    }
    closeWindow() {
        this.setState({ ...this.state, window: false });
    }

    render() {
        return (
            <PageContainer>
                <div ref={this.jumbotron}></div>
                {
                    this.state.browser !== 'safari' ?
                        <Zoom><OrangeBox text={this.props.text.contact.orangeBox} germanMod={this.props.text.lang === 'sr' || this.props.text.lang === 'en' ? true : false} /></Zoom>
                        :
                        <OrangeBox text={this.props.text.contact.orangeBox} germanMod={this.props.text.lang === 'sr' || this.props.text.lang === 'en' ? true : false} />
                }
                <Jumbotron background={Config.images.contact.background} backgroundMobile={Config.images.contact.backgroundMobile} />
                {
                    this.props.text && this.state.browser !== 'safari' ?
                        <>
                            <CookieModal show={this.props.cookies} />
                            {/* <Fade left> */}
                            <FullWrapper>
                                <ContactForm />
                            </FullWrapper>
                            {/* </Fade> */}
                            <Fade bottom>
                                <FullWrapper>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', textAlign: 'center' }}>
                                        <Paragraph>{this.props.text.contact.info}</Paragraph>
                                    </div>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', textAlign: 'center', marginBottom: '50px' }}>
                                        <Paragraph>{this.props.text.contact.telephone}</Paragraph>
                                    </div>
                                </FullWrapper>
                            </Fade>
                            <Fade clear>
                                <FullWrapper>
                                    <GoogleApiWrapper />
                                </FullWrapper>
                            </Fade>
                            <Fade bottom>
                                <PrivacyPolicy />
                            </Fade>
                            <Fade left>
                                <ScrollToTop onClick={() => this.scrollToTop()} />
                            </Fade>
                        </>
                        :
                        <>
                            {
                                this.props.text && this.state.browser === 'safari' ?
                                    <>
                                        <CookieModal show={this.props.cookies} />
                                        <FullWrapper>
                                            <ContactForm />
                                        </FullWrapper>
                                        <FullWrapper>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', textAlign: 'center' }}>
                                                <Paragraph>{this.props.text.contact.info}</Paragraph>
                                            </div>
                                            <br />
                                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', textAlign: 'center', marginBottom: '50px' }}>
                                                <Paragraph>{this.props.text.contact.telephone}</Paragraph>
                                            </div>
                                        </FullWrapper>
                                        <FullWrapper>
                                            <GoogleApiWrapper />
                                        </FullWrapper>
                                        <PrivacyPolicy />
                                        <ScrollToTop onClick={() => this.scrollToTop()} />
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                }
                <PopupWindow show={this.state.window} closeWindow={() => this.closeWindow()} />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, null)(Contact);