import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LargeHeight = '85px';

export const Container = styled.div`
width: 100%;
background-color: rgb( 29, 117, 189 );
border-top: 9px solid rgb( 247, 148 ,29);
height: ${LargeHeight};
white-space: nowrap;
@media only screen and (max-width: 1250px) {
    height: 65px;
}
`;

export const Inline = styled.div`
display: inline;
white-space: nowrap;
`;

export const Content = styled.div`
height: 100%;
margin-right: 15%;
float: right;
display: block;
width: auto;
white-space: nowrap;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}

`;
export const Element = styled(Link)`
text-decoration: none;
height: 100%;
width: auto;
margin-left: 25px;
display: inline-block;
white-space: nowrap;
@media only screen and (max-width: 1250px) {
    margin-left: 20px;
}
`;
export const Label = styled.p`
white-space: nowrap;
font-weight: bold;
font-family: "Ubuntu";
color: #FFFFFF;
margin-top: 42px;
font-size: 15px;
@media only screen and (max-width: 1250px) {
    font-size: 11px;
    margin-top: 36px;
}
@media only screen and (max-width: 950px) {
    font-size: 10px;
    margin-top: 37px;
    font-weight: normal;
}
:hover{
    cursor: pointer;
}
`;

export const Arrow = styled.img`
width: 15px;
height: 10px;
margin-left: 10px;
`;
export const Logo = styled.img`
display: inline-block;
width: auto;
height: auto;
margin-left: 15%;
margin-top: 20px;
@media only screen and (max-width: 1800px) {
    margin-left: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-left: 6%;
    width: auto;
    height: auto;
    max-width: 400px;
    margin-top: 26px;
}
@media only screen and (max-width: 1250px) {
    max-width: 300px;
    margin-top: 22px;
}
@media only screen and (max-width: 950px) {
    max-width: 250px;
    margin-top: 26px;
}
`;

export const DropDown = styled.div`
display: ${ props => { if (props.show === true) { return 'auto'; } else { return 'none'; } }};
position: absolute;
width: 130px;
height: 140px;
right: 13%;

/* background-color: rgba(86, 95, 110,0.3); */
background-color: rgb( 29, 117, 189 );
z-index: 3;
border-top: 0px solid gray;
border: 1px solid rgba(86, 95, 110 , 0.3);
text-align: left;
@media only screen and (max-width: 1800px) {
    right: 8%;
}
@media only screen and (max-width: 1500px) {
    right:3.5%;
}
@media only screen and (max-width: 1250px) {
    width: 110px;
    height: 130px;
}
@media only screen and (max-width: 950px) {
     right: 2.5%;
}
`;

export const ListItem = styled.p`
font-family: "Ubuntu";
font-size: 15px;
color: #FFFFFF;
padding-left: 20px;
margin-bottom: 5px;
font-weight: bold;
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 11px;
}
@media only screen and (max-width: 950px) {
    font-size: 10px;
}
:hover{
    cursor: pointer;
}
`;
export const SubContainer = styled.div`
position: relative;
`;