import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        text: state.language.trademark
    }
};

const Container = styled.div`
width: 100%;
background-color: rgb( 29, 117, 189 );
display: flex;
flex-direction: column;
height: 78px;
white-space: nowrap;
text-align: center;
@media only screen and (max-width: 1250px) {
    height: 65px;
}
`;

const Label = styled(Link)`
text-decoration: none;
vertical-align: center;
text-align: center;
display: flex;
align-self: center;
font-size: 14px;
color: #FFFFFF;
margin-top: 20px;
white-space: pre-line;
@media only screen and (max-width: 1250px) {
    margin-top: 15px;
}
`;

class Footer extends React.Component {
    render() {
        return (
            <Container>
                <Label to='/copyright' onClick={() => window.scrollTo(0, 0)}>{this.props.text ? this.props.text : ''}</Label>
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(Footer);