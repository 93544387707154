import React from 'react';
import { Background, Container, MessageContainer, Inline, ButtonLeft, ButtonRight, Buttons } from './styled';
import MainSection from '../MainSection';
import { Paragraph } from '../../styles';
import { connect } from 'react-redux';
import Actions from '../../store/Actions';
import Zoom from 'react-reveal/Zoom';

const mapStateToProps = (state) => {
    return {
        legal: state.language.legal
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        firstTime: () => dispatch(Actions.firstTime())
    }
}

class CookiesModal extends React.Component {
    onClick(type) {
        if (type === 'ac') {
            this.props.firstTime();
        }
        if (type === 'info') {
            this.props.firstTime();
            window.location.replace('/#/cookies');
        }
    }
    render() {
        if (this.props.show === true) {
            return (
                <Background show={this.props.show}>
                    <Container>
                        {
                            this.props.legal ?
                                <>
                                    <MainSection title={this.props.legal.cookies.title} noMargin={true}>
                                        <MessageContainer>
                                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                {this.props.legal.cookies.subtitle_1}
                                            </Paragraph>
                                            <Paragraph keepLine={true} >
                                                {this.props.legal.cookies.text_1}
                                            </Paragraph>
                                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                {this.props.legal.cookies.subtitle_2}
                                            </Paragraph>
                                            <Paragraph keepLine={true}>
                                                {this.props.legal.cookies.text_2}
                                            </Paragraph>
                                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                {this.props.legal.cookies.subtitle_3}
                                            </Paragraph>
                                            <Paragraph keepLine={true}>
                                                {this.props.legal.cookies.text_3}
                                            </Paragraph>
                                        </MessageContainer>
                                    </MainSection>
                                    <Buttons>
                                        <Inline>
                                            {
                                                this.props.legal ?
                                                    <>
                                                        <div style={{ marginRight: '20px' }}><ButtonRight onClick={() => this.onClick('ac')}>{this.props.legal.gdprWindow.accept}</ButtonRight></div>
                                                        <div style={{ marginLeft: '40px' }}><ButtonLeft onClick={() => this.onClick('info')}>{this.props.legal.gdprWindow.info}</ButtonLeft></div>
                                                    </>
                                                    :
                                                    <> </>
                                            }
                                        </Inline>
                                    </Buttons>
                                </>
                                :
                                <></>
                        }
                    </Container>
                </Background >
            )
        }
        else {
            return (<div></div>)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesModal);