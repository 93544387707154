import React from 'react';
import styled from 'styled-components';

import Config from '../../config';
const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;
const Image = styled.img`
display: flex;
align-self: center;
flex-direction: column;
width: 1000px;
height: auto;
margin-bottom: 70px;
@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 500px;
}
@media only screen and (max-width: 510px) {
    width: 400px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;

function MapImage(props) {
    switch (props.lang) {
        case 'en':
            return (
                <Container><Image src={Config.images.home.image_4_europe_en} /></Container>
            );
        case 'sr':
            return (
                <Container><Image src={Config.images.home.image_4_europe_srb} /></Container>
            );
        case 'de':
            return (
                <Container><Image src={Config.images.home.image_4_europe_de} /></Container>
            );
        default:
            return (
                <Container><Image src={Config.images.home.image_4_europe_en} /></Container>
            );
    }

};

export default MapImage;