import React from 'react';
import { Container, Section, Image, Column, Title, TextContent, ForReal, Wrapper, WrapperWrapper } from './styled';
import { Inline, Paragraph } from '../../styles';
import Config from '../../config';
import Fade from 'react-reveal/Fade';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        text: state.language.about
    }
}

class AboutContent extends React.Component {
    render() {
        return (
            <Container>
                {
                    this.props.text ?
                        <>

                            <Column>
                                <Inline>
                                    <Section first>
                                        <Image src={Config.images.home.image_1} />
                                        <WrapperWrapper><Title>{this.props.text.title_1}<Wrapper></Wrapper></Title></WrapperWrapper>
                                        <ForReal>
                                            <TextContent>
                                                <Paragraph>{this.props.text.text_1}</Paragraph>
                                            </TextContent>
                                        </ForReal>
                                    </Section>
                                    <Section>
                                        <Image src={Config.images.home.image_2} />
                                        <WrapperWrapper><Title>{this.props.text.title_2}<Wrapper></Wrapper></Title></WrapperWrapper>
                                        <ForReal>
                                            <TextContent>
                                                <Paragraph>{this.props.text.text_2}</Paragraph>
                                            </TextContent>
                                        </ForReal>
                                    </Section>
                                </Inline>
                            </Column>
                        </>
                        :
                        <>
                        </>
                }
            </Container>
        );
    }
}

export default connect(mapStateToProps, null)(AboutContent);