const deu = {
    lang: 'de',
    version: 2,
    navigation: {
        home: 'STARTSEITE',
        about: 'ÜBER UNS',
        certificate: 'GALERIE',
        history: 'GESCHICHTE',
        contact: 'KONTAKT',
        language: 'DEUTSCH',
        languageShort: 'DE',
        languages: {
            english: 'ENGLISCH',
            german: 'DEUTSCH',
            serbian: 'SERBISCH'
        }
    },
    about: {
        orangeBox: 'MAßGESCHNEIDETER TRANSPORT',
        about: 'ÜBER UNS',
        about_text_1: 'Wir sind Unternehmen MEGGA doo aus Pozega in Serbien. Unser Kerngeschäft ist der internationale Transport und die Spedition. Dank der Tradition und Inspiration des Gründers, der Produktivität und Motivation aller Mitarbeiter sind wir ein hervorragend organisiertes Transportunternehmen.',
        about_text_2: "Mit großer Erfahrung in Transport und Spedition schafften wir alle wichtigen Aspekte  zu verstehen, die für unsere Kunden von großer Bedeutung sind. Unser Fokus liegt immer auf dem, was der Partner benötigt. Das gemeinsame Ziel unseres Teams ist der Gewinn unserer Partner. Wir verfolgen jeden Schritt von der Buchung bis zur Realisierung. Dies unterscheidet uns von anderen in der Welt des Straßengüterverkehrs.",
        title_1: "MEGGA FLOTTE",
        text_1: "Die Megga-Flotte besteht aus Lkw der renommiertesten europäischen Lkw-Hersteller mit den neuesten technologischen Entwicklungen und der Euro6-Norm. Die ist einzigartig in ihrer nützlichen Innenhöhe von 3 m (Megatrailer). Das macht uns erkennbar. Das Durchschnittsalter unserer Fahrzeuge ist 3 Jahre. Alle unsere Fahrzeuge sind mit einem hochmodernen GPS-System ausgestattet und werden sieben Tage die Woche rund um die Uhr überwacht. Wir sind immer zur richtigen Zeit am richtigen Ort. Die Hauptaufgabe unserer LKW-Flotte ist die Sicherheit von Gütern im Transportwesen.",
        title_2: "MEGGA FAHRER",
        text_2: "Unsere Fahrer sind professionell, verantwortlich, zuverlässig und die zahlreichsten Mitglieder unseres Teams. Dies sind Profis, die für ihre engagierte Arbeit hochmotiviert sind. Die Hauptaufgabe unserer Fahrer ist die Verkehrssicherheit.",
        certificate_title: "ZERTIFIKATE",
        certificate_text: "Damit  die wichtigsten Anforderungen an die Sicherheit von Gütern im Transportbereich und Verkehrssicherheit erfüllt werden, widmet sich unser Unternehmen insbesondere der Normung. Aus diesem Grund verfügen unser Unternehmen, unsere Flotte und unsere Fahrer über mehrere international anerkannte Zertifikate. Wie alle sozial verantwortlichen Unternehmen legen wir großen Wert auf die Erhaltung der Umwelt, in der wir leben und schaffen.",
        locations_title: "ROUTEN",
        locations_text: "Unsere häufigsten Routen sind von Serbien und Balkanländern nach Österreich, Deutschland, Frankreich, Belgien, den Niederlanden, Polen, der Tschechischen Republik und der Slowakei. Auf Auftrag unserer Partner sind wir auch immer bereit, andere Transportrouten zu eröffnen.",
        partners_title: "UNSERE PARNTERE ÜBER UNS",
        partners_content: [
            {
                name: 'Heidi Gmeiner',
                title: 'Exportabteilung\nHermann Pfanner Getränke GmbH',
                text: "Dies ist ein Unternehmen, das außergewöhnlich vorsichtig ist, die Mitarbeiter sind sehr lieb und bemühen sich immer zu helfen. Wir können das Unternehmen Megga für den Transport von Österreich nach Serbien / Kosovo / Mazedonien absolut empfehlen."
            },
            {
                name: 'Mischa Jovanovic',
                title: 'CEO I Gründer\nAQUAWELL GROUP AG\nSaint John’s Wasser',
                text: "Als internationales Unternehmen war für uns sehr wichtig, einen zuverlässigen und gesunden Partner für die Zusammenarbeit zu haben. Megga ist das einzige Unternehmen in Serbien, das alle unsere Anforderungen erfüllt hat, das gewidmet, professionell und effizient den Transport zu allen Reisezielen erledigt, an denen unser Produkt angetrieben werden soll. Megga ist ein zuverlässiger Partner und wir haben eine langjährige Zusammenarbeit, mit der wir sehr zufrieden sind. Während all dieser Jahre der Zusammenarbeit mit der Firma Megga haben wir nur Dienstleistungen von höchster Qualität erhalten, ohne Verspätungen und Warenschäden."
            },
            {
                name: 'Goran Knezevic,',
                title: 'Easytrip Transport Services',
                text: "Eine außergewöhnliche Partnerschaft!\n\nDie Zusammenarbeit zwischen Easytrip und Megga begann vor fast 5 Jahren, als wir uns beim Feier des MDT-Vereins aus Kraljevo trafen. Das Unternehmen suchte nach einer Lösung für die slowakische Maut und seit diesem Tag haben wir eine hervorragende Geschäftsbeziehung aufgebaut, die auf Effizienz und Professionalität basiert. Heute arbeiten wir in fast allen europäischen Ländern zusammen.\n\nMegga bietet seinen Kunden einen perfekten Service an und ich bin stolz darauf, Teil ihres Erfolgs zu sein. Als zuverlässiger Lieferant und Experte in seinem Geschäft kann ich dieses Unternehmen mit großer Sicherheit empfehlen."
            },
        ]
    },
    history: {
        orangeBox: 'TRANSPORT TRADITION',
        history: 'UNTERNEHMENSGESCHICHTE VON MEGGA',
        historyText: "Das Unternehmen MEGGA doo Pozega ist ein Familienunternehmen, das seine Wurzeln schon in den zwanziger Jahren des letzten Jahrhunderts  und eine Tradition von weniger als 100 Jahren hat.",
        historyText2: 'Das erste Geschäftsmotto lautete: ',
        moto: "Für die Jüngeren bleibt die Welt.",
        historyText3: 'Der Grundstein für den heutigen Geschäftserfolg in den zwanziger Jahren des letzten Jahrhunderts legte unser Urgroßvater Dobrivoje Gligorijević, der in den dreißiger Jahren unsere Partnerschaft im Bereich des Güterverkehrs begann. Der Beginn des Geschäfts begann mit dem Transport von Lebensmitteln für die Ernährung von Menschen und Tieren. Er transportierte Heu, Getreide, Obst und Maschinen für die Herstellung und Verarbeitung von Lebensmitteln.',
        text1: "Bereits in den 1950er und 1960er Jahren des 20. Jahrhunderts nahm der Urgroßvater die industrielle Revolution in der Automobilindustrie und die Entstehung der ersten Lastkraftwagen vorweg und wies seine Erben und seinen Sohn an, sie im Umgang mit und im Fahren der ersten schweren Lastkraftwagen zu schulen.",
        text2: 'In den 70er und 80er Jahren des 20. Jahrhunderts wurde als LKW-Fahrer bei den großen Transportgiganten die Arbeit des Transports und Transports im nationalen und internationalen Transport von seinem Großvater Radmilo Gajo Gligorijević fortgesetzt und perfektioniert.',
        text3: 'In den 70er und 80er Jahren des 20. Jahrhunderts wurde als LKW-Fahrer bei den großen Transportgiganten die Arbeit des Transports und Transports im nationalen und internationalen Transport von seinem Großvater Radmilo Gajo Gligorijević fortgesetzt und perfektioniert.',
        text4: "In den frühen 2000er Jahren ergriff mein Vater, Dragan Gligorijevic, mit der Gründung von MEGGA doo Pozega die Initiative im Familienunternehmen. Er hat die Herausforderung der Tradition angenommen, neue moderne Kenntnisse und Technologien übernommen und implementiert. So hat er das Unternehmen bis heute auf ein höheres Niveau gebracht, als ich mich intensiv auf die Fortsetzung und Kontinuität im Geschäft vorbereite. Dank der Tradition Stefan Gligorijevic.",
    },
    contact: {
        orangeBox: 'BEI BEDARF AN TRANSPORT IST MEGGA DA',
        title: 'KONTAKT',
        name: 'Vor-und Nachname',
        email: 'E-Mail',
        message: 'Nachricht',
        send: 'ABSENDEN',
        info: "Megga doo\nVojvode Mišića 7\nPožega 31210\n\nE-mail: office@megga.pro",
        telephone: "Tel. +381 31 715 690\n     +381 31 715 729",
        success: 'Deine Nachricht wurde erfolgreich gesendet!',
        close: 'Schließen',
        failed: 'Fehler!',
        captcha: 'Incorrect captcha',
        messageError: 'Message is to short',
        nameError: 'Das Namensfeld darf nicht leer bleiben',
        emailError: 'Ungültige E-Mail-Adresse',
        textError: 'Der Text sollte mindestens 50 Zeichen lang sein',
    },
    legal: {
        copyright: {
            title: 'COPYRIGHT',
            text: "Die Webseite www.megga.pro und alle Inhalte dieser Website gehören\n Megga doo © 2020 Megga doo. Alle Urheberrechte und verwandte\n Schutzrechte sind dem Inhaber vorbehalten.\n\nEigentumsrechte beziehen sich konkret auf:\n1. Webseite ''www.megga.pro'';\n2.Alle Inhalte und Materialien der Webseite  meggapozega.com,\n einschließlich: aller Fotos und Videos auf der Webseite, Texte,\n Programmcode und alles andere, was nach dem Gesetz über Urheberrecht\n und verwandte Schutzrechte  als urheberrechtlich geschützt gelten kann.\n\nAlle Rechte sind vorbehalten. Jegliche Verwendung, Vervielfältigung oder\n Veröffentlichung des Inhalts der Website ganz oder teilweise ist strengstens\n untersagt. Ohne die ausdrückliche schriftliche Zustimmung des Inhabers ist es\n verboten, die Webseite oder den Inhalt der Website ganz oder teilweise für\n kommerzielle Zwecke zu nutzen und auf andere Weise wirtschaftlich zu\n nutzen.\n"
        },
        cookies: {
            title: 'COOKIE-RICHTLINIE',
            subtitle_1: 'Diese Webseite verwendet Cookies.',
            text_1: 'Diese Cookie-Richtlinie ( Cookie Policy ) soll erläutern, wie Megga doo Pozega die Cookies verwendet, lesen Sie daher diese Benachrichtigung sorgfältig durch, bevor Sie www.megga.pro ( die Webseite ) nutzen.',
            subtitle_2: 'Was sind Cookies?',
            text_2: "Cookies sind einfache Textdateien, die auf Ihrem Gerät innerhalb des Web-Browsers\n gespeichert werden, wenn Sie die Webseite besuchen. Die Cookies ermöglichen uns Sie und \nIhr Browser beim nächsten Besuch der Webseite zu erkennen. Jeder Cookie ist für jeden\n Browser einzigartig und enthält bestimmte Informationen wie z.B. Domainname und andere\n verschiedene Daten.\n",
            subtitle_3: "Welche Cookies verwenden wir?",
            text_3: "Analytische Cookies\n\nDiese Cookies ermöglichen uns und Dritten und ihren Servicen, Daten in aggregierter Form\n zur statistischen Analyse zu sammeln, wie unsere Benutzer die Webseite nutzen. Diese\n Cookies enthalten keine persönlichen Informationen über Benutzer, wie Namen oder E-Mails\n und werden verwendet, um die Nutzung der Website zu verbessern. Diese Website\n verwendet Cookies von Google Tag Manager (Google Analytics) und Facebook Pixel.\n",
        },
        privacy: {
            title: 'DATENSCHUTZRICHTLINIE',
            text_1: "Die Datenschutzrichtlinie dient dem Schutz der Privatsphäre von einzelnen Personen, die die Megga doo-Webseite unter www.megga.pro wie unten definiert („Kunden“ oder „Sie“, „Ihr“) nutzen und sich auf dieser registrieren. Diese Datenschutzrichtlinie beschreibt die Datenschutzpraktiken des Unternehmens in Bezug auf die Nutzung der Webseite des Unternehmens und der damit verbundenen Applikationen und Servicen, die vom Unternehmen angeboten werden. In dieser Datenschutzrichtlinie wird erläutert, wie wir die Informationen verarbeiten, die wir beim Besuch unserer Webseite sammeln. Außerdem werden Ihre Auswahlmöglichkeiten hinsichtlich der Verwendung, des Zugriffs und der Korrektur Ihrer privaten Daten beschrieben. Durch die Nutzung unserer Webseite und unserer Servicen verstehen Sie und stimmen zu, dass wir Ihre Informationen sammeln, speichern und verarbeiten. Wenn Sie den in dieser Datenschutzrichtlinie erläuterten Nutzungsbedingungen nicht zustimmen, sollten Sie unsere Webseite oder Service nicht nutzen.\n\nDie Datenschutzrichtlinie ist äußerst wichtig. Bitte lesen Sie die sorgfältig durch.\n\n",
            subtitle_2: "NUTZUNG DER WEBSEITE",
            text_2: "Informationen, die über die Applikation und Service bereitgestellt werden, ob durch Webseite-Besucher oder wenn Servicebenutzer Informationen eingeben, werden auf den Servern unserer Dienstanbieter gespeichert. Diese Informationen werden weiterhin verwendet, um Besucher und Benutzer über ihr Interesse an und die Interaktion mit den Diensten des Unternehmens zu informieren. Wir verarbeiten Informationen von unseren Kunden und speichern sie auf den Servern unserer Dienstleister, haben jedoch keine Kontrolle über deren Erfassung oder Verwaltung. Wir stimmen zu, dass Kunden das Recht haben, auf ihre persönlichen Daten zuzugreifen.\n",
            subtitle_3: "ERFASSTE INFORMATIONEN",
            text_3: "Es steht Ihnen frei, auf der Webseite des Unternehmens zu surfen, ohne private\n Informationen über sich selbst anzugeben. Bei Ihrer Registrierung bitten wir Sie, uns\n freiwillig personenbezogene Daten wie Ihren Namen, Ihre E-Mail-Adresse und dergleichen zur Verfügung zu stellen.",
            text_4: "Die Webseite des Unternehmens kann Links zu anderen Webseiten enthalten. Datenpraktiken oder der Inhalt anderer Webseite unterliegen den Datenschutzrichtlinien dieser anderen Webseite.\n",
            text_5: "Wenn Sie Ihre Einwilligung widerrufen wollen, können Sie dies jederzeit tun. Der Widerruf Ihrer Einwilligung hat weder Auswirkungen auf die Rechtmäßigkeit einer Verarbeitung, die wir vor Ihrem Widerruf durchgeführt haben, noch auf die Verarbeitung Ihrer persönlichen Daten, die auf legitimen Verarbeitungsgründen und nicht auf Einwilligung beruhen.\n",
            text_6: "Wir können Daten auch mithilfe von Datenerfassungstools,  wie Cookies erfassen. Zu diesen Informationen gehören Standardinformationen Ihres Webbrowsers (z. B. Browsertyp und Browsersprache), Ihre IP-Adresse (Internet Protocol) und die Aktionen, die Sie auf der Website des Unternehmens ausführen (z. B. angezeigte Webseiten). Die Daten werden vom Unternehmen verwendet, um die Qualität der Dienstleistungen aufrechtzuerhalten und allgemeine Statistiken zur Nutzung der Website des Unternehmens bereitzustellen. Weitere Informationen finden Sie unter Cookie-Richtlinien ( Cookie Policy ).\n",
            subtitle_4: "Urheberrechtserklärung ( Copyright statement )",
            text_7: "Die Webseite und alle darin enthaltenen Inhalte sind das alleinige Eigentum von Megga\n doo Pozega © 2020 Megga doo. Alle Rechte sind vorbehalten."
        },
        gdprWindow: {
            accept: 'Accept',
            info: 'More information'
        },
    },
    gdpr: {
        cookies: "COOKIE-BENACHRICHTIGUNG",
        privacy: "DATENSCHUTZ",
    },
    gallery: {
        all: 'ALLE',
        mercedes: 'MERCEDES',
        iveco: 'IVECO'
    },
    trademark: '© Megga doo Požega, All rights reserved,\nDesign, Spin digital'
}

export default deu;

