import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal';
import ReactImageAppear from 'react-image-appear';
import config from '../../config';
import { detect } from 'detect-browser';


const Container = styled.div`
width: 100%;
height: 100%;
z-index: 6;
background-color: rgba(0,0,0,0.7);
position: absolute;
top: 0;
left: 0;
display: ${ props => { if (props.show === true) { return 'flex'; } else { return 'none'; } }};
flex-direction: column;
justify-content: center;
`;
const ImageFull = styled.img`
width: auto;
height: 600px;

@media only screen and (max-width: 1250px) {
    height: 500px;
}
@media only screen and (max-width: 1000px) {
    height: 400px;
}
@media only screen and (max-width: 850px) {
    width: 100%;
    height: auto;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;

const Arrow = styled.img`
align-self: center;
flex-direction: row;
display: flex;
width: 25px;
height: 50px;
:hover{
  cursor: pointer;
}
${ props => {
        if (props.left === true) {
            return 'transform: rotate(180deg); -ms-transform: rotate(180deg); -moz-transform: rotate(180deg);  -webkit-transform: rotate(180deg);  -o-transform: rotate(180deg); margin-right: 30px; ';
        }
        if (props.right === true) {
            return 'margin-left: 30px;';
        }
    }}
`;

const Close = styled.img`
width: 30px;
height: 30px;
position: absolute;
:hover{
    cursor: pointer;
}
`;

class LargeImageShow extends React.Component {
    state = {
        selectedIndex: -1,
        browser: ''
    }
    componentDidMount() {
        const browser = detect();

        if (browser) {
            if (browser.name === 'safari ') {
                this.setState({ ...this.state, browser: 'safari' });
            }
            else {
            }
        }
    }
    render() {
        return (
            <Container show={this.props.show ? this.props.show : false}>
                <div style={{ alignSelf: "center", justifyContent: 'center', whiteSpace: 'nowrap', flexWrap: 'nowrap', display: 'flex' }} >
                    <Arrow src={config.images.general.ArrowRight} left={true} onClick={() => this.props.handleChange(this.props.index, 'left')} />
                    {
                        this.state.browser !== 'safari' ?
                            <Fade clear>
                                <ImageFull src={this.props.mobile ? config.images.gallery[this.props.index].large : config.images.gallery[this.props.index].large} animationDuration="1s" animation={'fadeIn'} />
                                <Close src={config.images.general.Close} onClick={() => this.props.close()} />
                            </Fade>
                            :
                            <>
                                <ImageFull src={this.props.mobile ? config.images.gallery[this.props.index].large : config.images.gallery[this.props.index].large} animationDuration="1s" animation={'fadeIn'} />
                                <Close src={config.images.general.Close} onClick={() => this.props.close()} />
                            </>
                    }
                    <Arrow src={config.images.general.ArrowRight} right={true} onClick={() => this.props.handleChange(this.props.index, 'right')} />
                </div>
            </Container>
        );
    }
}

export default LargeImageShow;