import styled from 'styled-components';

export const Container = styled.div`
margin-top: 40px;
display: inline-flex;
flex-wrap: nowrap;
align-self: center;
width: 1000px;
margin-bottom: 90px;
flex-direction: column;
display: -webkit-flex;
    display: -moz-flex;
    white-space: nowrap;

@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 500px;
    margin-top: 0px;
}
@media only screen and (max-width: 510px) {
    /* width: 400px; */
    width: 100%;
}
@media only screen and (max-width: 400px) {
    /* width: 275px; */
    width: 100%;
}
`;

export const ForReal = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;
export const TextContent = styled.div`
display: flex;
flex-direction: row;
align-self: center;
width: 100%;
@media only screen and (max-width: 850px) {
    width: 400px;
}
@media only screen and (max-width: 510px) {
    width: 350px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;
export const Column = styled.div`
display: flex;
flex-direction: column;
white-space: nowrap;
vertical-align: top;
margin-bottom: 50px;
@media only screen and (max-width: 850px) {
    margin-bottom: 0px;
}
`;
export const Section = styled.div`
display: inline-block;
vertical-align: top;
width: 45%;
margin-right: ${ props => props.first ? '10%' : '0'};
white-space: nowrap;
@media only screen and (max-width: 850px) {
    width: 80%;
    margin-left: 10%;
    display: block;
    margin-bottom: 50px;
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Inline = styled.div`
display: inline;
@media only screen and (max-width: 850px) {
    display: inline-block;
}
`;

export const Image = styled.img`
position: relative;

height: auto;
width: auto;
min-width: 100%;
max-width: 100%;
max-height: 300px;
`;

export const Content = styled.div`
width: 100%;
height: 150px;
background-color: yellow;
`;