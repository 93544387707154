import languages from '../../../locales';

function english() {
    return {
        type: 'LANGUAGE_CHANGE_ENGLISH',
        text: { ...languages.eng }
    };
}

function serbian() {
    return {
        type: 'LANGUAGE_CHANGE_SERBIAN',
        text: { ...languages.srb }
    };
}

function german() {
    return {
        type: 'LANGUAGE_CHANGE_GERMAN',
        text: { ...languages.deu }
    };
}

const changeLanguage = { serbian, english, german };

export default changeLanguage;