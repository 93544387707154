const srb = {
    lang: 'sr',
    version: 2,
    navigation: {
        home: 'NASLOVNA',
        about: 'O NAMA',
        certificate: 'GALERIJA',
        history: 'ISTORIJA',
        contact: 'KONTAKT',
        language: 'SRPSKI',
        languageShort: 'SR',
        languages: {
            english: 'ENGLESKI',
            german: 'NEMAČKI',
            serbian: 'SRPSKI'
        }
    },
    about: {
        orangeBox: 'TRANSPORT\nSKROJEN PO\nVAŠOJ MERI',
        about: 'O NAMA',
        about_text_1: 'Mi smo kompanija MEGGA doo iz Požege u Srbiji. Naša osnovna delatnost je međunarodni transport i špedicija. Zahvaljujući tradiciji i inspiraciji osnivača, produktivnosti i motivaciji svih zaposlenih mi smo vrhunski organizovana transportna kompanija.',
        about_text_2: "Velikim iskustvom u transportu i špediciji uspeli smo da sagledamo sve ključne aspekte koji su važni za naše klijente. Naš fokus je uvek na onome što zahteva partner. Zajednički cilj našeg tima je profit naših partnera. Pratimo svaki korak od rezervacije do realizacije. To je ono što nas izdvaja od drugih u svetu drumskog teretnog prevoza robe.",
        title_1: "MEGGA FLOTA",
        text_1: "Megga flotu čine kamioni najpoznatih evropskih proizvođača teretnjaka sa najnovijim tehnološkim dostignućima i euro6 standardom. Jedinstvena je po korisnoj unutrašnjoj visini prikolica od 3m (megatrailer). To je ono što nas čini prepoznatljivim. Prosečna starost naših vozila je 3 godine. Sva naša vozila opremljena su najmodernijim GPS sistemom i praćenjem sedam dana u nedelji, 24 sata dnevno. Mi smo uvek na pravom mestu u pravo vreme. Ključni zadatak naše flote kamiona je bezbednost robe u transportu.",
        title_2: "MEGGA VOZAČI",
        text_2: "Naši vozači su profesionalni, odgovorni, pouzdani i najbrojniji članovi našeg tima. To su profesionalci koji su visoko motivisani za svoj predani rad. Ključni zadatak naših vozača je bezbednost u saobraćaju.",
        certificate_title: "SERTIFIKATI",
        certificate_text: "U cilju ispunjavanja najvažnijih uslova bezbednosti robe u transportu i bezebednosti u saobraćaju naša kompanija posebno je posvećena standardizaciji. Zbog toga su naša kompanija, naša flota i naši vozači nosioci više međunarodno priznatih sertifikata. Takođe kao sve društeno odgovorne kompanije veliku brigu i pažnju posvećujemo očuvanju okruženja u kom živimo i stvaramo.",
        locations_title: "RELACIJE",
        locations_text: "Naše najčešće relacije su iz Srbije i zemalja Balkana do Austrije, Nemačke, Francuske, Belgije, Holandije, Poljske, Češke i Slovačke. Spremni smo uvek da na zahtev naših partnera otvorimo i druge transportne relacije.",
        partners_title: "PARTNERI O NAMA",
        partners_content: [
            {
                name: 'Heidi Gmeiner',
                title: 'Odeljenje za izvoz\nHermann Pfanner Getränke GmbH',
                text: "Firma koja vodi izuzetnu brigu, zaposleni su veoma dragi i trude se da pomognu. Apsolutno možemo da preporučimo firmu Megga za prevoz iz Austrije u Srbiju / Kosovo / Makedoniju."
            },
            {
                name: 'Mischa Jovanovic',
                title: 'CEO I Founder, AQUAWELL GROUP AG',
                text: "Kao kompanija koja se bavi međunarodnim biznisom, bilo nam je jako važno da imamo pouzdanog i zdravog partnera za saradnju. Megga je jedina kompanija u Srbiji koja je ispunila sve naše zahteve, koja posvećeno, profesionalno i efikasno obavlja transport na sve destinacije gde je potrebno da se vozi naš proizvod. Megga je pouzdan partner i mi imamo dugogodišnju saradnju kojom smo prezadovoljni. Za sve ove godine saradnje od Megga kompanije smo dobili samo najkvalitetnije usluge, bez kašnjenja i ostećenja robe."
            },
            {
                name: 'Goran Knežević',
                title: 'Easytrip Transport Services',
                text: "Izuzetno partnerstvo!\n\nSaradnja između Easytrip i Megga je započela pre gotovo 5 godina kada smo se sreli na proslavi krsne slave udruženja MDT iz Kraljeva. Kompanija je tražila rešenje za Slovačku putarinu i od tog dana smo izgradili odličan poslovni odnos zasnovan na efikasnosti i profesionalizmu. Danas sarađujemo u gotovo svim državama Evrope.\n\n Megga pruža savršenu uslugu svojim klijentima i ponosan sam što sam deo njihovog uspeha. Kao pouzdan dobavljač i stručnjak u svom poslu, mogu sa sigurnošću preporučiti ovu kompaniju."
            },
        ]
    },
    history: {
        orangeBox: 'TRADICIJA TRANSPORTA',
        history: 'ISTORIJA KOMPANIJE MEGGA',
        historyText: 'Kompanija MEGGA doo Požega je porodična firma koja svoje korene ima još u dvadestim godinama prošlog veka, sa tradicijom nešto manjom od 100 godina.',
        historyText2: 'Prvi poslovni moto bio je: ',
        moto: 'Na mlađima svet ostaje',
        historyText3: 'Dakle temelje današnjih poslovnih uspeha još davnih dvadestih godina prošlog veka postavio je naš pradeda, Dobrivoje Gligorijević, koji je još tridesetih godina započeo naše partnerstvo u oblasti usluga prevoza robe. Početak delatnosti započeo je prevozom hrane za ljudsku i životinjsku ishranu. Zapregom je prevozio seno, žito, voće i mašine za proizvodnju i preradu hrane.',
        text1: 'Već 50-tih i 60-tih godina 20. veka, pradeda je spremno iščekivao industrijsku revoluciju u automobilskoj industriji i pojavu prvih teških transportnih vozila pa je svoje naslednike i sina usmeravao i upućivao na obuku za rukovanje i vožnju prvih teških kamiona.',
        text2: 'Dalje 70-tih i 80-tih godina 20. veka kao vozač kamiona u velikim transportnim gigantima, posao prevoza i transporta u domaćem i međunarodnom transportu nastavio je i usavršavao deda, Radmilo Gajo Gligorijević. ',
        text3: 'Pojavom prvih privatnih transportnih kompanija 90-tih godina Gajo je sa svojim sinovima razvijao međunarodni trasport i špediciju u svojstvu preduzetnika - privatnika, zapošljavao ljude i prvi u to vreme nabavljao nove moderne kamione u standardima od Euro 2 i Euro 3 do Euro 4 i Euro 5.',
        text4: 'Početkom 2000-tih godina inicijativu u porodičnom biznisu osnivanjem kompanije MEGGA doo Požega preuzeo je moj otac, Dragan Gligorijević. Prihvatio je izazov tradicije, usvajao i implementirao nova savremena znanja i tehnologije. Tako je podizao rad kompanije na viši nivo sve do danas, kada se ja intezivno pripremam za nastavak i kontinuitet u poslovanju. Zahvalan tradiciji Stefan Gligorijević.',
    },
    contact: {
        orangeBox: 'KAD ZATREBA\nTRANSPORT\n TU JE MEGGA',
        title: 'KONTAKTIRAJTE NAS',
        name: 'Ime i Prezime',
        email: 'Email',
        message: 'Poruka',
        send: 'POŠALJI',
        info: "Megga doo\nVojvode Mišića 7\nPožega 31210\n\nE-mail: office@megga.pro",
        telephone: "Tel. +381 31 715 690\n     +381 31 715 729",
        success: 'Vaša poruka je uspešno poslata!',
        close: 'Zatvori',
        failed: 'Neuspešno slanje poruke',
        captcha: 'Incorrect captcha',
        messageError: 'Message is to short',
        nameError: 'Ime ne sme biti prazno',
        emailError: 'Email adresa mora da sadrži @',
        textError: 'Tekst treba da sadži minimum 50 karaktera',
    },
    legal: {
        copyright: {
            title: 'COPYRIGHT',
            text: "Internet stranica www.megga.pro kao i sav sadržaj na internet stranici su u vlasništvu Megga doo © 2020 Megga doo. Sva autorska i druga prava su zadržana od strane vlasnika.\n\nPravo svojine se konkretno odnosi na:\n\n1. Internet stranicu ''www.megga.pro''\n\n2. Sav sadržaj i materijal na internet stranici megga.pro, koji uključuju: sve fotografije i video zapise na internet stranici, tekstove, programerski kod kao i sve drugo što se prema Zakonu o autorskim i srodnim pravima može smatrati autorskim delom.\n\nSva prava su zaštićena. Svaka upotreba, reprodukcija ili objavljivanje, bilo u celosti bilo u delovima, sadržaja internet stranice je strogo zabranjena. Zabranjeno je bez izričitog pisanog pristanka vlasnika koristiti u komercijalne svrhe i na bilo koji drugi način ekonomski iskorišćavati, bilo u celosti ili u delovima, internet stranicu ili sadržaj sa internet stranice.\n",
        },
        cookies: {
            title: 'OBAVEŠTENJE O KOLAČIĆIMA',
            subtitle_1: 'Ova internet stranica koristi kolačiće.',
            text_1: 'Ovo Obaveštenje o kolačićima (“Cookie Policy”) ima svrhu da objasni kako Megga doo Požega\nkoristi kolačiće, prema tome pažljivo pročitajte ovo Obaveštenje pre nego što budete koristili\nwww.megga.pro ("internet stranica").\n',
            subtitle_2: 'Šta su kolačići?',
            text_2: "Kolačići su jednostavni tekstualni fajlovi koji se skladište na vašem uredjaju od strane interneta\nservera kada posetite internet stranicu. Kolačići nam omogućavaju da prepoznamo Vas i Vaš\npretraživač kada sledeći put budete posećivali internet stranicu. Svaki kolačić je jedinstven za\nsvaki pretraživač i sadržaće odredjene informacije kao što su imena domena i razni drugi\npodaci.\n",
            subtitle_3: "Koje kolačiće mi koristimo?",
            text_3: "Analitičke kolačiće\n\nOvi kolačići omogućavaju nama i trećim licima i njihovim servisima da prikupljaju podatke u\nagregovanoj formi za statističke analize o tome kako naši korisnici koriste internet stranicu. Ovi\nkolačići ne sadrže lične podatke o korisnicima kao što su imena ili e-mail i koriste se kako bi\npoboljšale korišćenje internet stranice. Ova internet stranica od kolačića koristi Google Tag\nManager (Google Analytics) i Facebook Pixel.\n",
        },
        privacy: {
            title: 'POLITIKA PRIVATNOSTI',
            text_1: "Politika Privatnosti (Privacy Policy) posvećena je zaštiti privatnosti pojedinaca koji koriste i koji\nse registruju na internet stranicu Kompanije Megga doo na adresi www.megga.pro kako su\ndole definisani (“Klijenti” ili “Vi”, “Vaš”). Ova Politika Privatnosti opisuje prakse privatnosti\nKompanije u vezi sa upotrebom internet stranice Kompanije i povezanih aplikacija i usluga koje\nnudi Kompanija. Ova politika privatnosti objašnjava kako obrađujemo podatke koje prikupljamo\nkada posetite našu internet stranicu. Takođe opisuje Vaše izbore u pogledu korišćenja, pristupa\ni korekcije vaših privatnih informacija. Koristeći našu internet stranicu i naše servise, Vi\nrazumete i prihvatate da skupljamo, skladištimo i procesuiramo Vaše informacije. Ako se ne\nsložite sa uslovima korišćenja podataka objašnjenim u ovo Politici Privatnosti, ne biste trebali\nkoristiti našu internet stranicu ili Servise.\n\nPolitika Privatnosti je izuzetno važna zato molimo pročitajte je pažljivo.\n\n",
            subtitle_2: "UPOTREBA INTERNET STRANICE",
            text_2: "Informacije obezbeđene kroz aplikaciju i servise, bilo preko posetilaca internet stranice ili kada\nkorisnici servisa unesu informacije, skladištena je na serverima našeg pružalaca usluga. Ove\ninformacije se dalje koriste za kontaktiranje posetilaca i korisnika, o njihovom interesovanju za\nusluge Kompanije i interakciji sa njima. Mi obrađujemo informacije naših klijenata i čuvamo ih\nna serverima našeg pružalaca usluga, ali nemamo kontrolu nad njihovim sakupljanjem ili\nupravljanjem. Saglasni smo da klijenti imaju prava da pristupe svojim Personalnim\ninforamacijama.\n",
            subtitle_3: "PRIKUPLJENE INFORMACIJE",
            text_3: "Vi ste slobodni da istražujete Internet stranicu Kompanije bez obezbeđivanja bilo kakvih\nprivatnih informacija o sebi. Kada se registrujete, Mi pitamo da nam dobrovoljno obezbedite\nlične podatke, kao što su Vaše ime, adresa elektronske pošte, i slično.\n",
            text_4: "Internet stranica Kompanije može sadržati linkove ka drugim veb sajtovima. Prakse u vezi sa\npodacima ili sadržaj drugih internet stranica regulišu se pomoću politika privatnosti tih drugih\ninternet stranica.\n",
            text_5: "Ako se odlučite da povučete vašu saglasnost, možete to učiniti u svakom trenutku. Povlačenje\nvaše saglasnosti neće uticati na zakonitost bilo kog procesuiranja koje smo obavili pre vašeg\npovlačenja, niti će uticati na procesuiranje vaših ličnih informacija koje se sprovode oslanjaju se\nna zakonite prerađivačke osnove pre nego na saglasnost.\n",
            text_6: "Mi takođe možemo sakupiti podatke kroz korišćenje alata za prikupljanje podataka, kao što su\nkolačići. Takvi podaci uključuju standardne podatke sa vašeg veb pretraživača (kao što je tip\npretraživača i jezik pretraživača), vaša internet protokol (IP) adresa, i radnje koje obavljate na\nVeb sajtu Kompanije (kao što su pregledane veb strane). Podatke koristi Kompanija za\nodržavanje kvaliteta usluga kao i da daje opštu statistiku u vezi sa korišćenjem internet stranice\nkompanije. Više o tome pogledajte u Obaveštenju o kolačićima(Cookie Policy)\n",
            subtitle_4: "Obaveštenje o autorskim pravima (Copyright statement)",
            text_7: "Internet stranica kao i sav sadržaj koji se u okviru nje nalazi su u isključivom vlasništvu Megga\ndoo Požega©2020 Megga doo. Sva prava su zadržana."
        },
        gdprWindow: {
            accept: 'Prihvatam',
            info: 'Više informacija'
        },
    },
    gdpr: {
        cookies: "OBAVEŠTENJE O KOLAČIĆIMA",
        privacy: "POLITIKA PRIVATNOSTI",
    },
    gallery: {
        all: 'SVE',
        mercedes: 'MERCEDES',
        iveco: 'IVECO'
    },
    trademark: '© Megga doo Požega, All rights reserved,\nDesign, Spin digital'
}

export default srb;