import React from 'react';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Navigation from '../components/Navigation';
import Gallery from '../pages/Gallery';
import History from '../pages/History';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Legal from '../pages/Legal';
import Footer from '../components/Footer';

class Main extends React.Component {

    render() {
        return (
            <Router >
                <div style={
                    {
                        width: "100vw",
                        height: "100vh"
                    }
                }>
                    <Navigation />
                    <Switch>
                        <Route exact path="/"
                            component={Home} />
                        <Route exact path="/about"
                            component={Home} />
                        <Route exact path="/gallery"
                            component={Gallery} />
                        <Route exact path="/history"
                            component={History} />
                        <Route exact path="/contact"
                            component={Contact} />
                        <Route exact path="/contact_submit"
                            component={Contact} />
                        <Route exact path="/copyright"
                            component={Legal} />
                        <Route exact path="/privacy"
                            component={Legal} />
                        <Route exact path="/cookies"
                            component={Legal} />
                    </Switch>
                    <Footer />
                </div>
            </Router>
        );
    }

}

export default Main