import React from 'react';
import { Background, Container, MessageContainer, Inline, ButtonLeft, ButtonRight, Buttons } from './styled';
import MainSection from '../MainSection';
import { Paragraph } from '../../styles';
import { connect } from 'react-redux';
import Actions from '../../store/Actions';
import Zoom from 'react-reveal/Zoom';

const mapStateToProps = (state) => {
    return {
        text: state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        firstTime: () => dispatch(Actions.firstTime())
    }
}

class PopupWindow extends React.Component {
    onClick() {
        this.props.closeWindow();
    }
    render() {
        if (this.props.show === true) {
            return (
                <Background show={this.props.show}>
                    <Container>
                        {
                            this.props.text ?
                                <>
                                    <MainSection title={this.props.text.contact.success} noMargin={true}>
                                    </MainSection>
                                    <Buttons>
                                        <Inline>
                                            {
                                                this.props.text ?
                                                    <>
                                                        <div style={{ marginRight: '20px' }}><ButtonRight onClick={() => this.onClick()}>{this.props.text.contact.close}</ButtonRight></div>
                                                    </>
                                                    :
                                                    <> </>
                                            }
                                        </Inline>
                                    </Buttons>
                                </>
                                :
                                <></>
                        }
                    </Container>
                </Background >
            )
        }
        else {
            return (<div></div>)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PopupWindow);