import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Config from '../../config';

class App extends Component {
    marker = {
        key: 1,
        position: {
            lat: 43.845270,
            lng: 20.040210
        },
    }
    render() {
        return (
            <div style={{ position: 'relative', marginBottom: '80vh' }}>
                <Map
                    google={this.props.google}
                    style={{ width: '100vw', height: '80vh' }}
                    gestureHandling={'cooperative'}
                    initialCenter={{
                        lat: 43.845270,
                        lng: 20.040210
                    }}
                    zoom={18}
                ><Marker {...this.marker} /></Map>
            </div>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (Config.googleMapsAPI)
})(App);