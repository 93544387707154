import React from 'react';
import styled from 'styled-components';

const Text = styled.h3`
    position: absolute;
    /* background-color: rgba(227, 137, 52, 0.9); */
    background-color: rgba(249, 108, 15, 0.8);
    
    color: #FFFFFF;
    font-family: Ubuntu;
    right: 250px;       
    top: 175px;        
    ${ props => {
        if (props.germanMod === true) {
            return 'padding-left: 30px; padding-right: 30px; padding-top: 50px;  padding-bottom: 60px; font-size: 40px;'
        }
        else {
            return 'padding-left: 30px; padding-right: 30px; padding-top: 50px;  padding-bottom: 60px; font-size: 60px; ';
        }
    }}  
    height: 500px;
    width: 400px;  
    height: auto;       
    /* overflow: scroll; */
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */

    @media only screen and (max-width: 1800px ){
        top: 80px;
        right: 125px;
        font-size: 50px;
        ${ props => { if (props.germanMod === true) { return 'font-size: 40px' } }};
    }
    @media only screen and (max-width: 1500px ){
        top: 80px;
        width: 350px;
        right: 50px;
        font-size: 50px;
        ${ props => { if (props.germanMod === true) { return 'font-size: 35px' } }};
    }
    @media only screen and (max-width: 1250px ){
        top: 40px;
        width: 250px;
        right: 40px;
        font-size: 40px;
        ${ props => { if (props.germanMod === true) { return 'font-size: 25px' } }};
    }

    @media only screen and (max-width: 925px ){

        top: 60px;
        width: 200px;
        right: 40px;
        font-size: 30px;
        padding-bottom: 30px;
        padding-top: 30px;
        ${ props => { if (props.germanMod === true) { return 'font-size: 20px' } }};
    }
    @media only screen and (max-width: 600px ){

        top: 90px;
        width: 200px;
        right: 20px;
        font-size: 25px;
        padding-bottom: 10px;
        padding-top: 10px;

        ${ props => { if (props.germanMod === true) { return 'font-size: 21px' } }};
        ${
    props => {
        if (props.leftCorner === true) {
            return 'right: none; left: 20px; margin-top: -20px; width: 180px;'
        }
        if (props.closeToTop === true) {
            return 'margin-top: -20px ; right: 10%;'
        }
    }
    }
    }
    @media only screen and (max-width: 420px ){
        ${
    props => {
        if (props.leftCorner === true) {
            return 'margin-top: -20px; width: 180px; top: none; margin-top: 95%;'
        }
    }
    }
    }
`;
const Container = styled.div`
position: relative;
text-align: center;
vertical-align: center;
white-space: pre-line;
`;

function OrangeBox(props) {
    return (
        <Container>
            <Text germanMod={props.germanMod} leftCorner={props.leftCorner} closeToTop={props.closeToTop}>{props ? props.text : ''}</Text>
        </Container>
    );
}

export default OrangeBox;