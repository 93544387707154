import styled from 'styled-components';


export const InputField = styled.input.attrs({
    placeholderTextColor: "red"
})`
font-size: 16px;
width:100%;
padding-left: 15px;
font-family: 'Ubuntu';
border-radius: 3px;
background-color: rgba(115, 114, 115, 0.0);
border: 1px solid rgba(90,90,90, 1);
color: rgb( 46 , 135 ,195 );
height: 36px;
margin-top: 20px;
::-webkit-input-placeholder {
    color: rgb( 46 , 135 ,195 );}
`

export const TextArea = styled.textarea`
font-family: 'Ubuntu';

font-size: 16px;
width:98%;
padding-top: 15px;
padding-right: 15px;
padding-left: 15px;
border-radius: 3px;
background-color: rgba(115, 114, 115, 0.0);
border: 1px solid rgba(90,90,90, 1);
color: rgb( 46 , 135 ,195 );
height: 150px;
margin-top: 20px;
@media only screen and (max-width: 850px) {
    width: 96%;
}
@media only screen and (max-width: 400px) {
    width: 95%;
}
::-webkit-input-placeholder {
    color: rgb( 46 , 135 ,195 );}
`
